import React from 'react';
import { Button } from '@headlessui/react';

const sections = [
  { id: 'info', label: 'Information' },
  { id: 'Details', label: 'Details' },
  { id: 'Author', label: 'Author' },
  { id: 'reviews', label: 'Reviews' },
  { id: 'things-to-know', label: 'Terms Of Use' },
];

const ResourseButtons = ({ activeSection, onButtonClick }) => {
  return (
    <div className='xxxs:py-2 xxxs:px-1 xxs:py-2 xxs:px-1 xs:py-2 xs:px-1 
      sm:py-4 sm:px-1 lg:py-4 lg:px-1 xl:py-4 xl:px-1 1xl:py-4 1xl:px-1 11xl:py-4
      11xl:px-1 2xl:py-4 2xl:px-1 3xl:py-4 3xl:px-1 bg-white 
       xxxs:w-full xxs:w-full xs:w-full xxxxs:w-full xxxxxs:w-full
      sm:w-[600px] lg:w-[600px] xl:w-[700px] 1xl:w-[800px] 11xl:w-[760px] 2xl:w-[900px] 3xl:w-[1100px] 
      flex'>
      {sections.map(section => (
        <Button
          key={section.id}
          onClick={() => onButtonClick(section.id)} // Add onClick handler here
          className={`bg-gray-200 text-black rounded xxxs:text-xs xxs:text-xs xs:text-xs sm:text-lg 
            lg:text-lg xl:text-lg 1xl:text-lg 11xl:text-lg 2xl:text-lg 3xl:text-lg xxxs:px-1 xxxs:py-1
            xxxs:m-1  xxs:px-1 xxs:py-1 xxs:m-1  xs:px-1 xs:py-1 xs:m-1 lg:px-4 lg:py-2 lg:m-2 
            xl:px-4 xl:py-2 xl:m-2 1xl:px-4 1xl:py-2 1xl:m-2 11xl:px-4 11xl:py-2 11xl:m-2 
            2xl:px-4 2xl:py-2 2xl:m-2 3xl:px-4 3xl:py-2 3xl:m-2
            ${activeSection === section.id ? 'bg-primary-color text-white' : 'hover:bg-gray-300'}`}>
          {section.label}
        </Button>
      ))}
    </div>
  );
}

export default ResourseButtons;

