import { useState } from 'react';
import { MinusIcon, PlusIcon, PlayCircleIcon, LockClosedIcon } from '@heroicons/react/24/outline';

const CollapseFaq = ({ title, subTitle }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleContent = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className='
    sm:m-2 lg:m-2 xl:m-2 1xl:m-2 11xl:m-2 2xl:m-2 22xl:m-2 3xl:m-2 '>
      <div className='flex justify-between'>
        <div className='flex'>
          <h3 className={`xxxxxs:text-sm xxxxs:text-sm xxxs:text-sm xxs:text-sm xs:text-sm
              lg:text-lg xl:text-lg 1xl:text-lg 11xl:text-lg 2xl:text-lg 22xl:text-lg 3xl:text-lg font-bold ${isOpen ? 'text-primary-color' : 'text-big-text-color'}`}>
            {title}
          </h3>
        </div>

        {isOpen ? (
          <MinusIcon className="w-6 h-6 cursor-pointer text-primary-color" onClick={toggleContent} />
        ) : (
          <PlusIcon className="w-6 h-6 cursor-pointer text-big-text-color" onClick={toggleContent} />
        )}
      </div>

      {isOpen && (
        <div>
          <div className="justify-between flex my-2">
            <div className="flex">
              <PlayCircleIcon className="w-6 h-6" />
              <h4 className="
              xxxxxs:text-sm xxxxs:text-sm xxxs:text-sm xxs:text-sm xs:text-sm
              lg:text-lg xl:text-lg 1xl:text-lg 11xl:text-lg
               2xl:text-lg 22xl:text-lg 3xl:text-lg text-gray-700
                ml-2">{subTitle}</h4>
            </div>
           
          </div>
        </div>
      )}
    </div>
  );
};

export default CollapseFaq;
