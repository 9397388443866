import React from 'react';
import { MapPinIcon, PhoneIcon, EnvelopeIcon } from '@heroicons/react/24/solid';
import image from '../../../asset/images/team-1.webp.png';
import face from '../../../asset/images/Symbol.png';
import twitter from '../../../asset/images/Symbol (1).png';
import yout from '../../../asset/images/Symbol (2).png';
import HalfLogoInTeam from '../../../asset/images/halfinteam.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faTiktok, faLinkedinIn, faWhatsapp, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
const InstructorSection =()=>{

    return (
        <div className=" sm:flex lg:flex xl:flex 1xl:flex 11xl:flex 2xl:flex 3xl:flex space-x-4 sm:min-w-0 sm:flex-1 sm:space-x-6 lg:space-x-8  h-full">
        <div className='flex justify-center items-center pl-4 pr-4'>
<img
  src={image}
  alt={image}
  className="w-30 h-30 rounded-md object-cover sm:w-60 sm:h-60"
/>
</div>

          <div className=" mt-8 min-w-0 flex-1 sm:pt-0">
            <h3 className="text-lg font-bold text-big-text-color">
            Dr. Tarek Eltantawy
            </h3>
            <h3 className=" mt-2 text-sm font-medium text-yellow-700">
            Ceo Manager
            </h3>
            <div className='flex mb-2 mt-3'>
              <MapPinIcon className='w-4 h-4 text-gray-400 mr-1' />
              <h4 className='text-sm font-normal text-gray-500'>Egypt</h4>
            </div>
            <p className='font-normal text-sm text-gray-500'>
            Histudy The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested.
            </p>
            <div className='flex mt-5 mb-3'>
                <div className='w-10 h-10 mr-4 bg-[#F6F6F6] flex items-center justify-center'>
                <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
    <FontAwesomeIcon icon={faFacebookF} className='text-primary-color' />
</a>

                </div>
                <div className='w-10 h-10 mr-4 bg-[#F6F6F6] flex items-center justify-center'>
                <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
    <FontAwesomeIcon icon={faTwitter} className='text-primary-color' />
</a>

                </div>
                <div className='w-10 h-10 mr-4 bg-[#F6F6F6] flex items-center justify-center'>
                <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
    <FontAwesomeIcon icon={faYoutube} className='text-primary-color' />
</a>

                </div>
              </div>
            <div className='flex'>
              <div className='flex mb-2 mt-2 mr-5'>
                <PhoneIcon className='w-4 h-4 text-gray-400 mr-1' />
                <h4 className='text-sm font-normal text-gray-500'>+1-202-555-0174</h4>
              </div>
              <div className='flex mb-2 mt-2'>
                <EnvelopeIcon className='w-4 h-4 text-gray-400 mr-1' />
                <h4 className='text-sm font-normal text-gray-500'>Ahmed@gmail.com</h4>
              </div>
            </div>
          </div>
          <img
            src={HalfLogoInTeam}
            alt={HalfLogoInTeam}
            className="flex-none  rounded-md object-center object-cover sm:w-30 sm:h-40 hidden-mobile"
          />
        </div>
    )
}
export default InstructorSection;