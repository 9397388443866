import React from "react";
import CustomTextTerms from "./customTextInsideTerms"; // Custom reusable text block component
import image from "../../../asset/images/Rectangle 42337.png"; // Optional background image

const StackTerms = () => {
  return (
    <div className="flex flex-col min-h-screen">
      {/* Gradient Background */}
      <div className="bg-custom-gradient-div h-80 text-white flex items-center justify-center">
        <h1 className="text-4xl font-bold">Terms and Conditions</h1>
      </div>

      {/* Main Content */}
      <div className="relative top-[-230px] mx-4 sm:mx-28 mt-10 mb-10 bg-white flex flex-col shadow-lg items-center justify-center rounded-lg">
        <h3 className="text-2xl font-bold mb-4 mt-10 text-big-text-color">
          Terms and Conditions
        </h3>

        {/* Sections */}
        <div className="w-full space-y-10 px-4 sm:px-20 mb-10">
          {/* Acceptance of Terms */}
          <Section
            title="Acceptance of Terms"
            content="By using the Website, you agree to comply with these Terms and all applicable laws, rules, and regulations. If you do not agree with these Terms, you must not use the Website."
          />

          {/* Changes to Terms */}
          <Section
            title="Changes to Terms"
            content="We reserve the right to modify or update these Terms at any time. Changes will be posted on this page with an updated 'Last Updated' date. It is your responsibility to review these Terms periodically. Your continued use of the Website after any changes to these Terms signifies your acceptance of those changes."
          />

          {/* Use of Website */}
          <Section title="Use of Website">
            <p className="text-lg text-gray-800 mb-3">
              By using the Website, you agree to comply with these Terms and all applicable laws, rules, and regulations. If you do not agree with these Terms, you must not use the Website.
            </p>
            <ul className="list-disc pl-5 space-y-2 text-gray-800">
              <li>Use the Website in any way that violates any applicable local, state, national, or international law or regulation.</li>
              <li>Interfere with or disrupt the Website's operation or servers or networks connected to the Website.</li>
              <li>Attempt to gain unauthorized access to any part of the Website, other accounts, or systems connected to the Website.</li>
            </ul>
          </Section>

          {/* Account Registration */}
          <Section
            title="Account Registration"
            content="In some areas of the Website, you may be required to create an account. You are responsible for maintaining the confidentiality of your account login information and for all activities that occur under your account. You agree to notify us immediately if you suspect any unauthorized use of your account."
          />

          {/* Privacy Policy */}
          <Section
            title="Privacy Policy"
            content="Your use of the Website is also governed by our [Privacy Policy](insert link to Privacy Policy), which outlines how we collect, use, and protect your personal information."
          />

          {/* Disclaimers and Limitation of Liability */}
          <Section
            title="Disclaimers and Limitation of Liability"
            content="We will not be liable for any damages arising from the use or inability to use the Website, including but not limited to any direct, indirect, incidental, special, consequential, or punitive damages, even if we have been advised of the possibility of such damages."
          />
        </div>
      </div>
    </div>
  );
};

/**
 * Section Component
 * Reusable component for sections in terms and conditions.
 */
const Section = ({ title, content, children }) => (
  <div>
    <h3 className="text-xl sm:text-2xl font-bold text-start mb-3 text-big-text-color">
      {title}
    </h3>
    {content && <CustomTextTerms title={content} />}
    {children}
  </div>
);

export default StackTerms;
