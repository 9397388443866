import React from 'react';
import CustomMarkTextLink from './custommarktextlink';

const  ResourcsSection =()=>{
   return (
    <div className=' xxxs:w-full xxs:w-full xs:w-full xxxxs:w-full xxxxxs:w-full
    sm:w-[600px] lg:w-[600px] xl:w-[700px] 1xl:w-[800px] 11xl:w-[760px] 2xl:w-[900px] 3xl:w-[1100px]   bg-white rounded-custom mt-5 p-2'>
      <h3 className='xxxs:text-xl xxs:text-xl xs:text-xl sm:text-3xl 
    lg:text-3xl xl:text-3xl 1xl:text-3xl 11xl:text-3xl 2xl:text-3xl 3xl:text-3xl font-bold text-big-text-color'>
      Resources
      </h3>
     <CustomMarkTextLink title='Katy Chandler on LinkedIn:' url='https://www.linkedin.com/feed/' />
     <CustomMarkTextLink title='DuraServ Website:' url='https://www.linkedin.com/feed/' />
     <CustomMarkTextLink title='DuraServ LinkedIn:' url='https://www.linkedin.com/feed/'/>
    </div>
   )

}

export default ResourcsSection;