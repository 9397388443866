import React from "react";
import img from '../../../asset/images/formnws.webp';
import DropdownWithHintNewsLetter from './customDropDownNewsLetter';
import CustomBigTitle from "../homeuser/custombigtitle";

const FormNewsLetter = () => {
  return (
    <div className="
      xxxxxs:px-4 xxxxs:px-4 xxxs:px-4 xxs:px-4 xs:px-4
    sm:px-24 md:px-24 mid:px-24 lg:px-24 xl:px-24 1xl:px-24 11xl:px-24 2xl:px-24 3xl:px-24 
    mt-10 mb-10">
      <div className="flex justify-center items-center">
        <img
          src={img}
          alt="NewsLetter"
          className="w-1/4"
        />
      </div>
      <CustomBigTitle title='To keep you up-to-date about industry news and provide timely information about trends, BIS publishes a number of newsletters.' />

      <div className=" block rounded-lg bg-white  shadow-lg mb-20 p-6">

        <form>
          {/* Label and Input for First Name and Last Name */}
          <div className="flex mb-6">
            <div className="w-1/2 pr-2">
              <input
                id="first-name"
                type="text"
                placeholder="first name"
                maxLength="12" // Limit to 12 characters
                className="w-full px-4 py-3 text-xs sm:text-lg border border-gray-300 rounded-lg"
              />
            </div>
            <div className="w-1/2 pl-2">
              <input
                id="last-name"
                type="text"
                placeholder=" last name"
                maxLength="12" // Limit to 12 characters
                className="w-full px-4 py-3 text-xs sm:text-lg border border-gray-300 rounded-lg"
              />
            </div>
          </div>

          {/* Label and Input for Email and Phone */}
          <div className="flex mb-6">
            <div className="w-1/2 pr-2">
              <input
                id="email"
                type="email"
                placeholder="email"
                maxLength="50" // Limit email to 50 characters
                className="w-full px-4 py-3 text-xs sm:text-lg border border-gray-300 rounded-lg"
              />
            </div>
            <div className="w-1/2 pl-2">
              <input
                id="phone"
                type="tel"
                placeholder="phone number"
                maxLength="15" // Limit phone number to 15 characters (for international format)
                className="w-full px-4 py-3 text-xs sm:text-lg border border-gray-300 rounded-lg"
              />
            </div>
          </div>

          {/* Label and Input for Nationality and Dropdown */}
          <div className="flex mb-6">
            <div className="w-1/2 pr-2">
              <input
                id="nationality"
                type="text"
                placeholder="nationality"
                maxLength="30" // Limit nationality to 30 characters
                className="w-full px-4 py-3 text-xs sm:text-lg border border-gray-300 rounded-lg"
              />
            </div>
            <div className="w-1/2 pl-2">
              <DropdownWithHintNewsLetter />
            </div>
          </div>

          {/* Message Input */}
          <div className="w-full pr-2 mb-10">
            <textarea
              id="message"
              placeholder="Your Message..."
              maxLength="200" // Limit message to 200 characters
              className="w-full px-4 text-lg border border-gray-300 rounded-lg h-40 resize-none"
            />
          </div>

          {/* Submit Button */}
          <div className="flex justify-center mb-10">
            <button
              type="submit"
              className="block w-1/2 rounded-lg bg-primary-color px-8 py-3 text-base font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              Send Now
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default FormNewsLetter;
