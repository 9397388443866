import React from 'react';
const CustomTitleAndImage = ({ imageSrc, title, subtitle }) => {
  return (
    <div className="  justify-center items-center ">
    <div>
      <h2 className="xxxs:text-xs xxs:text-xs xs:text-xs
       sm:text-sm lg:text-sm  xl:text-lg 1xl:text-lg 11xl:text-lg 2xl:text-xl 22xl:text-xl 3xl:text-xl
        text-base font-normal leading-7 tracking-tight text-big-text-color ml-2 ">{title}</h2>
      <p className="
     xxxs:text-xs xxs:text-xs xs:text-xs
       sm:text-sm lg:text-sm  xl:text-sm 1xl:text-sm 11xl:text-sm 2xl:text-lg 22xl:text-lg 3xl:text-lg
      font-normal leading-6 text-gray-400 ml-2 ">{subtitle}</p>
    </div>
  </div>
  );
};

export default CustomTitleAndImage;
