import React, { useState, useEffect } from 'react';
import HeadUser from '../../components/users/sharedcomponent/headuser';
import HeaderHomeUse from '../../components/users/sharedcomponent/headerhomeuser';
import FlyoutMenu from '../../components/users/sharedcomponent/flayoutmenus';
import CustomDivider from '../../components/users/sharedcomponent/customdivider';
import CustomDiv from '../../components/users/coursesuser/customdiv';
import SortedSideBar from '../../components/users/coursesuser/sortedsidebar';
import AllCourseGrid from '../../components/users/coursesuser/allcoursesgrid';
import AllCoursesList from '../../components/users/coursesuser/allcourselist';
import FooterUser from '../../components/users/sharedcomponent/footeruser';
import CustomNavBarWithFlayout from '../../components/users/sharedcomponent/customnavbarwithflayout';
import HeaderHomeUserMobile from '../../components/users/sharedcomponent/customMobileHead';
import CustomSortedForMobileCourses from '../../components/users/coursesuser/customSideSortedCourseMobile';
import CustomStaticFlyoutMenu from '../../components/users/sharedcomponent/customFlayoutinStaticHeader';
import CustomLoadMoreButton from '../../components/users/sharedcomponent/customLoadMoreButton';
import SortByDropdownMobile from '../../components/users/coursesuser/customsortbyindivMobile';
const AllCourses = () => {
  const [view, setView] = useState('grid');
  const [isNavBarVisible, setIsNavBarVisible] = useState(false);

  const handleGridPress = () => setView('grid');
  const handleListPress = () => setView('list');
  const handleSort = (sortOption) => {
    // Logic for sorting based on the selected option
    console.log(`Sorting by ${sortOption}`);
  };
  useEffect(() => {
    const handleScroll = () => {
      setIsNavBarVisible(window.scrollY > 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="relative">
      <div className={`transition-all duration-300 ${isNavBarVisible ? 'pt-16' : 'pt-0'}`}>
        <HeadUser />
        <HeaderHomeUse />
        <CustomDivider />
        <div className='flex justify-center items-center hidden-mobile'>
          <CustomStaticFlyoutMenu  />
        </div>
      </div>
      <CustomDivider />
      <div className={`fixed mobile-only top-0 left-0 w-full  bg-white z-50 transition-transform duration-300 ${isNavBarVisible ? 'translate-y-0' : '-translate-y-full'}`}>
    <HeaderHomeUserMobile />
</div>

        <div className={`fixed hidden-mobile top-0 left-0 w-full bg-white z-50 transition-transform duration-300 ${isNavBarVisible ? 'translate-y-0' : '-translate-y-full'}`}>
          <CustomNavBarWithFlayout />
        </div>
      <div className='bg-gray-50 z-0'>
        <CustomDiv

        navTitle='Courses'
          onGridPress={handleGridPress}
          onListPress={handleListPress}
          title='All Courses'
          subTitle="Courses provide a structured framework, guiding learners through a well-organized curriculum that ensures comprehensive coverage of the subject matter."
          buttonTitle="20 courses"
          activeView={view}
        />
<div className=" flex  justify-between px-4 mt-5 sm:hidden">
  <CustomSortedForMobileCourses />
 <SortByDropdownMobile onSort={handleSort} />

</div>



        <div className='relative 
         lg:top-[-140px] xl:top-[-140px]
         1xl:top-[-150px] 11xl:top-[-150px]
         2xl:top-[-140px] 22xl:top-[-150px] 3xl:top-[-150px]
           lg:mx-24 xl:mx-24 1xl:mx-24 11xl:mx-24 2xl:mx-24 22xl:mx-24 3xl:mx-24 px-1 mt-10  flex'>
          <div className='sticky top-[80px] hidden-mobile'>
            <SortedSideBar />
          </div>
          <div className='xxxs:mx-4 xxs:mx-4 xs:mx-4 xxxxs:mx-4 xxxxxs:mx-4
                     sm:mx-0 mid:mx-0 md:mx-0 lg:mx-0 xl:mx-0 1xl:mx-0 11xl:mx-0 
                     2xl:mx-0 22xl:mx-0 3xl:mx-0 ' >
          {view === 'grid' ? <AllCourseGrid /> : <AllCoursesList />}
          <div className='flex justify-center items-center mt-20 xxxs:mb-10 xxs:mb-10 xs:mb-10 xxxxs:mb-10 xxxxxs:mb-10'>
  <CustomLoadMoreButton children='Load More'/>
</div>

          </div>
        </div>
        <FooterUser />
      </div>
    </div>
  );
};

export default AllCourses;
