import React ,{useState,useEffect} from 'react';
import HeadUser from '../../components/users/sharedcomponent/headuser';
import HeaderHomeUse from '../../components/users/sharedcomponent/headerhomeuser';
import CustomDivider from '../../components/users/sharedcomponent/customdivider';
import FooterUser from '../../components/users/sharedcomponent/footeruser';
import StackPrivacy from '../../components/users/privacyuser/stackprivacy';
import FlyoutMenu from '../../components/users/sharedcomponent/flayoutmenus';
import CustomNavBarWithFlayout from '../../components/users/sharedcomponent/customnavbarwithflayout';
import CustomStaticFlyoutMenu from '../../components/users/sharedcomponent/customFlayoutinStaticHeader';
import HeaderHomeUserMobile from '../../components/users/sharedcomponent/customMobileHead';
import CustomSectionIntro from '../../components/users/newlettersuser/customSectionIntro';
import SectionFaq from '../../components/users/faquser/sectionFaq';
import SectionQuestionFaq from '../../components/users/faquser/sectionQuestionFaq';
const Faq =()=>{
    const [isNavBarVisible, setIsNavBarVisible] = useState(false);
    const [scrollingDown, setScrollingDown] = useState(true);
  
    useEffect(() => {
      let lastScrollTop = 0;
  
      const handleScroll = () => {
        const currentScrollTop = window.scrollY;
  
        setScrollingDown(currentScrollTop > lastScrollTop);
        setIsNavBarVisible(currentScrollTop > 100); // Adjust threshold as needed
  
        lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop; // For Mobile or negative scrolling
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);
  
    return (
      <div className="relative">
        {/* This div will handle the padding-top adjustment for the fixed navbar */}
        <div className={`transition-all duration-300 ${isNavBarVisible ? 'pt-16' : 'pt-0'}`}>
          <HeadUser />
          <HeaderHomeUse />
          <CustomDivider />
          <div className='flex justify-center items-center hidden-mobile'>
            <CustomStaticFlyoutMenu />
          </div>
        </div>
        <CustomDivider />
        <div className={`fixed mobile-only top-0 left-0 w-full  bg-white z-50 transition-transform duration-300 ${isNavBarVisible ? 'translate-y-0' : '-translate-y-full'}`}>
      <HeaderHomeUserMobile />
  </div>
  
          <div className={`fixed hidden-mobile top-0 left-0 w-full bg-white z-50 transition-transform duration-300 ${isNavBarVisible ? 'translate-y-0' : '-translate-y-full'}`}>
            <CustomNavBarWithFlayout />
          </div>
<SectionFaq/>
<SectionQuestionFaq/>
      <FooterUser/>
     </div>
      )
  }
export default Faq;