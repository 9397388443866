import React from 'react';
import CustomSearchBar from './customsearchbar';
import { QueueListIcon, TableCellsIcon } from '@heroicons/react/24/solid';
import img from '../../../asset/images/Icongray.png';
import CustomButtonDiv from './customButtonDiv';
import SortByDropdown from './customsearchbar';

const CustomDiv = ({ onGridPress, onListPress, title, subTitle, buttonTitle, activeView  ,navTitle }) => {
  // Define base styles for buttons and icons
  const buttonBaseStyle = "text-neutral-700 rounded border border-none w-32 h-10 shadow-sm bg-transparent hover:bg-neutral-100 focus:outline-none active:bg-white";
  const activeButtonStyle = "bg-white text-primary-color"; // Change text color to yellow when active
  const iconBaseStyle = "w-4 h-4"; // Base size of the icon
  const activeIconStyle = "text-primary-color"; // Change icon color to yellow when active
  const handleSort = (sortOption) => {
    // Logic for sorting based on the selected option
    console.log(`Sorting by ${sortOption}`);
  };
  return (
    <div className='pt-4 pb-4 xxxs:px-10 xxs:px-10 xs:px-10 sm:px-20 lg:px-28 xl:px-28 1xl:px-28 11xl:px-28 2xl:px-28 3xl:px-28 bg-custom-gradient-div min-h-[300px] lg:min-h-[400px] text-white'>
      <div className='flex flex-col lg:flex-row lg:items-center lg:justify-between mt-5'>
        <div className='flex items-center'>
          <h3 className='text-xs font-bold mr-4 text-big-text-color'>Home</h3>
          <img
            src={img}
            alt="Icon"
            className=' w-2 h-3'
          />
           <h3 className='text-xs  font-normal ml-4 text-big-text-color'>{navTitle}</h3>

        </div>
      </div>
      <div className='flex flex-col lg:flex-row lg:items-center '>
          <h3 className='text-2xl font-bold mt-3 mr-5 text-big-text-color'>{title}</h3>
          <div className='mt-8'>
            <CustomButtonDiv name={buttonTitle} />
          </div>
        </div>
      <p className='mb-5 text-sm text-big-text-color'>{subTitle}</p>
      <div className='flex flex-col lg:flex-row justify-between items-center'>
        <div className='flex items-center mb-4 lg:mb-0'>
          <div className="flex items-center p-4 bg-white-300 w-full lg:w-[200px] h-[50px] rounded-lg hidden-mobile">
            <button
              className={`${buttonBaseStyle} ${activeView === 'grid' ? activeButtonStyle : ''} flex items-center justify-center space-x-2`}
              onClick={onGridPress}
            >
              <TableCellsIcon
                className={`${iconBaseStyle} ${activeView === 'grid' ? activeIconStyle : ''}`}
              />
              <span className={`${activeView === 'grid' ? 'text-yellow-500' : ''}`}>Grid</span>
            </button>

            <button
              className={`${buttonBaseStyle} ${activeView === 'list' ? activeButtonStyle : ''} flex items-center justify-center space-x-2`}
              onClick={onListPress}
            >
              <QueueListIcon
                className={`${iconBaseStyle} ${activeView === 'list' ? activeIconStyle : ''}`}
              />
              <span className={`${activeView === 'list' ? 'text-yellow-500' : ''}`}>List</span>
            </button>
          </div>
          <p className='ml-3 text-sm text-gray-800'>Showing 1-6 of 20 results</p>
        </div>
     <div className='hidden-mobile'>
     <SortByDropdown onSort={handleSort} />  
      </div>  
          </div>
      {/* Render the custom content below the search bar */}

    </div>
  );
};

export default CustomDiv;
