import React, { useState } from 'react';
import CustomItemInStore from './customiteminstore';
import storeOne from '../../../asset/images/v6yJvmklfriHNMqvBYlwsVzmLaxw3nGjCcWvziCm 1.png';
import storeTwo from '../../../asset/images/v6yJvmklfriHNMqvBYlwsVzmLaxw3nGjCcWvziCm 1 (2).png';
import storeThree from '../../../asset/images/v6yJvmklfriHNMqvBYlwsVzmLaxw3nGjCcWvziCm 1 (1).png';
import CustomButton from '../sharedcomponent/custombutton';
import CustomBigTitle from './custombigtitle';
import AllResourcesListInHome from './listofresoursesinhome';
import ListOfCourseHome from './listofCoursesHome';
import CustomEvents from './customEvents';
import CustomBlogs from './customBlogs';
const AllServicesSections = () => {
  const [activeButton, setActiveButton] = useState('Courses');

  const handleButtonClick = (button) => { 
    setActiveButton(button); 
  };

  return (
    <div className='bg-big-text-color pb-5 pt-10 flex flex-col items-center h-[800px]'>
      <div className='flex items-center justify-center mt-10'>
        <CustomButton name='Last Chance'/>
      </div>

      <h2 className="
      xxxxxs:text-lg
       lg:text-3xl xl:text-3xl  2xl:text-4xl uppercase
        font-semibold tracking-tight text-white items-center text-center  mb-5">
        What We Provide
        </h2>
      <div className='flex mb-5 '>
        {['Courses',  'Resources','Events','Blogs' ].map((button) => (
          <button
            key={button}
            type="button"
            onClick={() => handleButtonClick(button)}
            className={`xxxs:mx-1 xxs:mx-1 xs:mx-1 lg:mx-4 xl:mx-4 1xl:mx-4 11xl:mx-4 2xl:mx-4 3xl:mx-4
               inline-block rounded-custom border-2 xxxs:px-1 xxs:px-1 xs:px-1
                lg:px-6 xl:px-6 1xl:px-6 11x:px-6 2xl:px-6 3xl:px-6 pb-[6px] pt-2 text-xs
                 font-medium uppercase leading-normal transition duration-150 ease-in-out 
              ${activeButton === button ? 'border-primary-color bg-primary-color text-white' : 'border-primary-color text-primary-color hover:border-gray-600 hover:bg-gray-100 hover:text-gray-800'}`}
          >
            {button}
          </button>
        ))}
      </div>

      <div className=' mt-5 w-full max-w-full xxxs:px-6 xxxs:mx-4 xxs:px-6  xxs:mx-4
       xs:px-6 xs:mx-4 xxxxs:px-6 xxxxs:mx-4  xxxxxs:px-6 xxxxxs:mx-8 sm:px-6 md:px-8 lg:px-12 xl:px-10'>
        {activeButton === 'Courses' && <ListOfCourseHome />}
        {activeButton === 'Resources' && <AllResourcesListInHome />}
        {activeButton === 'Events' && <CustomEvents />}
        {activeButton === 'Blogs' && <CustomBlogs />}


      </div>
    </div>
  );
};

export default AllServicesSections;