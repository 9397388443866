import React, { useEffect, useState, useRef } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import image from '../../../asset/images/goodevent.png';
import CustomIconAndText from '../sharedcomponent/customiconandtext';
import { MapPinIcon, ClockIcon } from "@heroicons/react/24/outline";
import CustomTitleAndImageOnly from '../sharedcomponent/imageandtitleonly';
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/24/solid';
import arrowRightImage from '../../../asset/images/arrowrightwhite.png';
import imageteam from '../../../asset/images/d1af7d099058eb24786ab692d7f35bbc.png';
import imageResourseOne from '../../../asset/images/imagesres.jpg';
import imageResourseTwo from '../../../asset/images/imagesr.jpg';
import { useNavigate } from 'react-router-dom';
import BookmarkButton from '../sharedcomponent/customBookMark';
import CustomParagraph from '../sharedcomponent/customParagraph';
import CustomIconAndTextDate from '../sharedcomponent/customTitleAndIconDates';
import CustomButton from '../sharedcomponent/custombutton';
const products = [
  {
    id: 1,
    name: 'How To Upgrade ',
    href: '#',
    contant: 'Leverage exclusive ATD templates and tools to develop the most powerful, bottom-line focused learning',
    imageSrc: image,
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 2,
    name: 'Grow your business',
    href: '#',
    contant: 'Utilize innovative learning frameworks and resources to create effective, results-driven training.',
    imageSrc: imageResourseOne,
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 3,
    name: 'Advices In Yours',
    href: '#',
    contant: 'Leverage exclusive ATD templates and tools to develop the most powerful, bottom-line focused learning',
    imageSrc: imageResourseTwo,
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 4,
    name: 'How To Upgrade ',
    href: '#',
    contant: 'Leverage exclusive ATD templates and tools to develop the most powerful, bottom-line focused learning',
    imageSrc: image,
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 5,
    name: 'Grow your business',
    href: '#',
    contant: 'Utilize innovative learning frameworks and resources to create effective, results-driven training.',
    imageSrc: imageResourseOne,
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 6,
    name: 'Advices In Your ',
    href: '#',
    contant: 'Leverage exclusive ATD templates and tools to develop the most powerful, bottom-line focused learning',
    imageSrc: imageResourseTwo,
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  // Additional products...
];

export default function CustomEvents() {
  const scrollRef = useRef(null);
  const navigate = useNavigate();

  const scrollOneItem = (direction) => {
    if (scrollRef.current) {
      const itemWidth = scrollRef.current.children[0].offsetWidth;
      const scrollAmount = direction === 'left' ? -itemWidth : itemWidth;
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const handleClick = () => {
    navigate('/eventInside');
  };

  return (
    <div className="bg-transparent justify-center items-center px-2 pb-5">
      <div className="justify-center items-center xxxs:px-10 xxs:px-10
       xs:px-10 xxxxs:px-10 xxxxxs:px-10 lg:px-24 mx-auto">
        <div className="relative flex items-center">
          {/* Left Arrow */}
          <button
            onClick={() => scrollOneItem('left')}
            className="absolute left-[-50px] z-10 p-2 text-white bg-primary-color rounded-full shadow-md"
            style={{ top: '50%', transform: 'translateY(-50%)' }}
          >
            <ArrowLeftIcon className="w-6 h-6" />
          </button>

          {/* Scrollable Container */}
          <div
            ref={scrollRef}
            className="flex overflow-x-auto sm:p-4 w-full scrollbar-hidden space-x-3"
            style={{ scrollSnapType: 'x mandatory' }}
          >
            {products.map((product) => (
              <div
                key={product.id}
                className="relative flex-none w-full sm:w-1/2 md:w-1/3
                 lg:w-1/3 xl:1/4 min-w-[100px] lg:min-w-[25%] rounded-lg shadow-sm bg-white p-4"
                style={{ scrollSnapAlign: 'start' }}
              >
                <div className="aspect-h-1 aspect-w-1 w-full 
                overflow-hidden rounded-md bg-gray-200 lg:h-40 mb-3">
                  <img
                    alt={product.imageAlt}
                    src={product.imageSrc}
                    className="h-full w-full object-cover object-center"
                  />
                </div>
                <div className="flex justify-between mt-5">
                  <h2 className="lg:text-lg xl:text-lg text-big-text-color font-semibold cursor-pointer" onClick={handleClick}>
                    {product.name}
                  </h2>
                  <BookmarkButton />
                </div>
                <div className="flex mt-2">
                  <CustomIconAndText
                    icon={<MapPinIcon className="h-4 w-4 text-gray-500" />}
                    title="Cairo, Egypt"
                    link="https://www.google.com/maps"
                  />
                  <CustomIconAndTextDate
                    icon={<ClockIcon className="h-4 w-4 text-gray-500" />}
                    title="16 Nov, 2024"
                  />
                </div>
                <CustomParagraph title={product.contant} />
                <CustomTitleAndImageOnly
                  navigateTo="/eventInside"
                  imageSrc={imageteam}
                  title="Business"
                />
                <div className="flex justify-between mt-1 items-center">
                  <p className="text-lg font-medium text-primary-color">{product.price}</p>
                  <button
                    onClick={handleClick}
                    className="text-sm text-white border
                     rounded-custom bg-primary-color px-3 py-2 flex items-center"
                  >
                    See Details
                  </button>
                </div>
              </div>
            ))}
          </div>

          {/* Right Arrow */}
          <button
            onClick={() => scrollOneItem('right')}
            className="absolute right-[-50px] z-10 p-2 text-white bg-primary-color rounded-full shadow-md"
            style={{ top: '50%', transform: 'translateY(-50%)' }}
          >
            <ArrowRightIcon className="w-6 h-6" />
          </button>
        </div>
      </div>
    </div>
  );
}
