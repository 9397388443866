import React from 'react';

const CustomCardForNewsLetter = ({ title, imageUrl, subTitle, navigateTo }) => {
 
  const handleCardClick = () => {
    window.open(navigateTo, '_blank');
  };

  return (
    <div
      className="flex justify-center items-center py-10 cursor-pointer"
      onClick={handleCardClick}
    >
      <div
        className="relative flex-none 
        w-full
        rounded-custom py-4 bg-white px-6"
      >
        {/* Card Image */}
        <div className="relative mb-5 h-40 overflow-hidden rounded-md bg-gray-200">
          <img
            alt={title}
            src={imageUrl}
            className="h-full w-full object-cover object-center"
          />
        </div>

        <h2 className="text-2xl mb-3 text-big-text-color font-semibold cursor-pointer text-center">
          {title}
        </h2>

        {/* Card Content */}
        <div className="flex flex-col items-center justify-center mt-5">
          <p
            style={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 3,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontSize: '0.75rem',
              color: '#4B5563',
              textAlign: 'center',
              marginTop: '0.5rem',
            }}
          >
            {subTitle}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CustomCardForNewsLetter;
