import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StarReview from "../../admin/reviews";
import image from '../../../asset/images/62b1de2e8e142538f54863b6_What is course design.jpg';
import CustomIconAndText from '../sharedcomponent/customiconandtext';
import {CreditCardIcon,UsersIcon,BookmarkIcon  } from "@heroicons/react/24/outline";
import CustomTitleAndImage from '../sharedcomponent/customtitleandimage';
import imagecourse from '../../../asset/images/events.png';
import imagecoursetwo from '../../../asset/images/istock-1220226086.jpg';
import imageCourseThree from '../../../asset/images/onlinecourses_shutterstock_490891228_2000px_728945.jpg';
import CustomTitleAndImageOnly from "../sharedcomponent/imageandtitleonly";
import img from '../../../asset/images/Icon.png';
import { useNavigate } from 'react-router-dom';
import CustomParagraph from "../sharedcomponent/customParagraph";
import imageTeamCourse from '../../../asset/images/sideimage.png'
import BookmarkButton from '../sharedcomponent/customBookMark';

const products = [
  {
    id: 1,
    name: 'Instructional Design',
    href: '#',
    imageSrc: imagecourse,
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 2,
    name: 'Instructional Design',
    href: '#',
    imageSrc: imagecoursetwo,
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 3,
    name: 'Instructional Design',
    href: '#',
    imageSrc: imageCourseThree,
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 4,
    name: 'Instructional Design',
    href: '#',
    imageSrc: imagecourse,
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 5,
    name: 'Instructional Design',
    href: '#',
    imageSrc: imagecoursetwo,
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 6,
    name: 'Instructional Design',
    href: '#',
    imageSrc: imageCourseThree,
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
];


export default function AllCourseGrid() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/courseInside'); 
  };

  return (
    <div className="flex w-full mx-auto xxxs:px-0 xxs:px-0 xs:px-0  xxxxs:px-0 xxxxxs:px-0 sm:px-6 mid:px-0 md:px-0
      lg:px-8 xl:px-2 1xl:px-2  11xl:px-2  2xl:px-2 22xl:px-2 3xl:px-2">
      <div className="w-full  mx-auto">
        <div className="grid grid-cols-1   lg:gap-x-8 xl:gap-x-8 1xl:gap-x-8
         11xl:gap-x-8 2xl:gap-x-10 22xl:gap-x-10 3xl:gap-x-8
         gap-y-12 xxxs:grid-cols-1 xxs:grid-cols-1
          xs:grid-cols-1 sm:grid-cols-2 lg:grid-cols-3
           xl:grid-cols-3 11xl:grid-cols-3 1xl:grid-cols-3 
           2xl:grid-cols-3 22xl:grid-cols-3 3xl:grid-cols-4">
          {products.map((product) => (
            <div key={product.id}
            className="group relative
               xxxs:w-full xxs:w-full xs:w-full sm:w-full 
            lg:w-[240px] xl:w-[240px] 11xl:w-[250px] 1xl:w-[260px] 2xl:w-[310px]
             22xl:w-[350px]
              3xl:w-[300px] lg:mx-0 xl:mx-0 2xl:mx-10 xxxs:2xl:mx-0 xxs:mx-0 xs:mx-0 sm:mx-0  md:mx-0 mid:mx-0
                bg-white rounded-lg shadow-lg overflow-hidden transition-transform duration-300
                 transform hover:scale-105">
             <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden
              bg-white lg:aspect-none group-hover:opacity-75 rounded-custom">
                <img
                  alt={product.imageAlt}
                  src={product.imageSrc} 
                  className="h-[150px] w-full object-cover object-center p-4 rounded-custom"
                />
              </div>
              <div className="px-5 pb-5">
                <div className="flex justify-between items-center">
                  <StarReview />
                  <BookmarkButton/>
                </div>
                <h2 
  className="lg:text-lg xl:text-lg 2xl:text-2xl text-big-text-color font-semibold cursor-pointer" 
  onClick={handleClick}
>
  {product.name}
</h2>

                <div className="flex space-x-4 mt-2">
                  <CustomIconAndText icon={<CreditCardIcon className="sm:h-3 sm:w-3 lg:h-3 lg:w-3
                   xl:h-3 xl:w-3 1xl:h-3 1xl:w-3 11xl:h-3 11xl:w-3 2xl:h-5 2xl:w-5 22xl:h-5 22xl:w-5 
                    3xl:h-3 3xl:w-3 text-gray-500" />} title='14 courses' />
                  <CustomIconAndText icon={<UsersIcon className="sm:h-3 sm:w-3 lg:h-3 lg:w-3
                   xl:h-3 xl:w-3 1xl:h-3 1xl:w-3 11xl:h-3 11xl:w-3 2xl:h-5 2xl:w-5 22xl:h-5 22xl:w-5 
                    3xl:h-3 3xl:w-3  text-gray-500" />} title='14 students' />
                </div>
               
                <CustomParagraph
                title=' This course offers a comprehensive introduction to the principles and practices of instructional design, aimed at equipping students with the necessary skills.'
                />
                <CustomTitleAndImageOnly
                  imageSrc={imageTeamCourse}
                  title="Tarek Tantawy"
                />
                <div className="mt-4 mb-5 flex justify-between items-center">
                  <p className="text-lg font-medium text-big-text-color">{product.price}</p>
                  <button
                    onClick={handleClick}
                    className="mt-1 text-sm text-gray-500 border rounded border-gray-400 bg-transparent px-2 py-1 flex items-center justify-center">
                    Enroll Course
                    <img
                      src={img}
                      alt="Course Icon"
                      className="ml-2"
                    />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}