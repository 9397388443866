import React, { useState, useEffect, useRef } from 'react';
import {PaperClipIcon} from '@heroicons/react/24/outline'; // Import custom icon if needed
import HeadUser from '../../components/users/sharedcomponent/headuser';
import HeaderHomeUse from '../../components/users/sharedcomponent/headerhomeuser';
import CustomDivider from '../../components/users/sharedcomponent/customdivider';
import FlyoutMenu from '../../components/users/sharedcomponent/flayoutmenus';
import CustomDivCourseInside from '../../components/users/coursesuser/customdivcourseinside';
import SideBarCourseInside from '../../components/users/coursesuser/sidebarcourseinside';
import AboutShop from '../../components/users/shopuser/aboutshop';
import InstructorSection from '../../components/users/coursesuser/instructorsection';
import ReviewsInsideCourse from '../../components/users/coursesuser/reviewsinsidecours';
import MakeAReview from '../../components/users/coursesuser/makereview';
import CustomThingsToDo from '../../components/users/coursesuser/customthingstodo';
import CustomContactInsideCourse from '../../components/users/coursesuser/customcontactinsidecourse';
import CustomButton from '../../components/users/sharedcomponent/custombutton';
import ScrollInROWCourseList from '../../components/users/coursesuser/scrollinrowcourselist';
import FooterUser from '../../components/users/sharedcomponent/footeruser';
import CustomNavBarWithFlayout from '../../components/users/sharedcomponent/customnavbarwithflayout';
import StoreDetials from '../../components/users/shopuser/storedetials';
import ShopButtons from '../../components/users/shopuser/shopbuttons';
import img from '../../asset/images/yellorighticon.png';
import { useNavigate } from 'react-router-dom';
import ListInsideShops  from '../../components/users/shopuser/listshopsinside';
import HeaderHomeUserMobile from '../../components/users/sharedcomponent/customMobileHead';
import ReviewsInsideShop from '../../components/users/shopuser/reviewinsideshop';
import SideBarShopInsideMobile from '../../components/users/shopuser/customSideShopInsideMobile';
import CustomStaticFlyoutMenu from '../../components/users/sharedcomponent/customFlayoutinStaticHeader';
import ListInsideShopsMobile from '../../components/users/shopuser/listShopInsideMobile';
const StoreInside = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/allshops'); 
  };
  const [isNavBarVisible, setIsNavBarVisible] = useState(false);
  const [activeSection, setActiveSection] = useState('Description');
  const [isThreeButtonsVisible, setIsThreeButtonsVisible] = useState(false);

  const aboutCourseRef = useRef(null);
  const courseContentRef = useRef(null);
  const handleButtonClick = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setActiveSection(sectionId); // Update the active section
    }
  };
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.scrollY;

      // Update navbar visibility
      setIsNavBarVisible(currentScrollTop > 100);

      const sections = ['Description', 'Specifications', 'Seller', 'reviews', 'things-to-know'];
      sections.forEach(sectionId => {
        const element = document.getElementById(sectionId);
        if (element) {
          const { offsetTop, clientHeight } = element;
          if (currentScrollTop >= offsetTop && currentScrollTop < offsetTop + clientHeight) {
            setActiveSection(sectionId);
          }
        }
      });

      // Handle visibility of ThreeButtonsInRow
      if (courseContentRef.current) {
        const courseContentTop = courseContentRef.current.offsetTop;
        setIsThreeButtonsVisible(currentScrollTop >= courseContentTop);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="relative">
      <div className={`transition-all duration-300 ${isNavBarVisible ? 'pt-16' : 'pt-0'}`}>
        <HeadUser />
        <HeaderHomeUse />
        <CustomDivider />
        <div className='flex justify-center items-center hidden-mobile'>
          <CustomStaticFlyoutMenu  />
        </div>
      </div>
      <CustomDivider />
      <div className={`fixed mobile-only top-0 left-0 w-full  bg-white z-50 transition-transform duration-300 ${isNavBarVisible ? 'translate-y-0' : '-translate-y-full'}`}>
    <HeaderHomeUserMobile />
</div>

        <div className={`fixed hidden-mobile top-0 left-0 w-full bg-white z-50 transition-transform duration-300 ${isNavBarVisible ? 'translate-y-0' : '-translate-y-full'}`}>
          <CustomNavBarWithFlayout />
        </div>
      <div className='bg-gray-100 z-0'>
        <CustomDivCourseInside
         languageIcon={<PaperClipIcon className="h-4 w-4 text-gray-500" />} 
         language='12 downlad'
         
         showLanguageIcon={true}
         showLanguageText={true}
         showReview={true}
         onClick={handleClick}
          date="16 Nov, 2024"
        navTitle='Store'
        navTitleTwo='Geometric Notebook'
        title='Geometric Notebook'
        />
        <div className='mobile-only'>
        <SideBarShopInsideMobile/>
        </div>
        <div className="flex  xxxs:m-2 xxs:m-2 xs:m-2 sm:m-10 lg:m-10 xl:m-10 1xl:m-10 11xl:m-10 2xl:m-10 3xl:m-10
         xxxs:px-4 xxs:px-4 xs:px-4 lg:px-16 xl:px-16 1xl:px-16 11xl:px-16 2xl:px-16 3xl:px-20">
        <div className="flex-grow ">
            {isThreeButtonsVisible && (
              <div className={`transition-transform duration-200 ${isThreeButtonsVisible ? 'fixed top-[80px] z-40' : 'relative'}`}>
                <ShopButtons activeSection={activeSection} 
                  onButtonClick={handleButtonClick} 
                />
              </div>
            )}
                  <ShopButtons activeSection={activeSection}
                    onButtonClick={handleButtonClick} 
                  />
            <div id="Description" ref={aboutCourseRef}>
              <AboutShop/>
            </div>
            <div id="Specifications" ref={courseContentRef}>
              <StoreDetials />
            </div>
            <div className="xxxs:w-full xxs:w-full xs:w-full xxxxs:w-full xxxxxs:w-full
        sm:w-[600px] lg:w-[600px] xl:w-[700px] 1xl:w-[800px] 11xl:w-[760px] 2xl:w-[900px] 3xl:w-[1100px] 
              ">
              <div
                id='Seller' className='flex flex-col items-start justify-start mt-5 bg-white p-5'>
                <h3 className="xxxs:text-xl xxs:text-xl xs:text-xl sm:text-3xl lg:text-3xl xl:text-3xl 1xl:text-3xl 11xl:text-3xl 2xl:text-3xl 3xl:text-3xl font-bold text-big-text-color">
                  Seller</h3>
            
                  <div className='shadow-lg mt-3'>
               <InstructorSection />
               </div>
              </div>
            </div>
            <div id='reviews'>
              {/* <ReviewsInsideShop /> */}
              <MakeAReview />
            </div>
         
            <div id='things-to-know'>
              <CustomThingsToDo title='Things To Know'/>
            </div>
            <CustomContactInsideCourse />
          </div>
          <div className=' relative top-[-350px] w-[400px] mb-10 mt-10 
           sm:ml-10 lg:ml-10 xl:ml-20 1xl:ml-20 11xl:ml-20 2xl:ml-20 3xl:ml-20  hidden-mobile '>
            <div className='sticky top-[80px]'> {/* Adjust top value to match height of CustomNavBarWithFlayout */}
              <SideBarCourseInside />
            </div>
          </div>
        </div>
  <div className='hidden-mobile'>
  <ListInsideShops className="m-0 p-0" />
  </div>
<div className='mobile-only'>
<ListInsideShopsMobile/>
</div>
      </div>
      <FooterUser />
    </div>
  );
};

export default StoreInside;