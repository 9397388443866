import React, { useState, useEffect, useRef  } from 'react';
import HeadUser from '../../components/users/sharedcomponent/headuser';
import HeaderHomeUse from '../../components/users/sharedcomponent/headerhomeuser';
import CustomDivider from '../../components/users/sharedcomponent/customdivider';
import FlyoutMenu from '../../components/users/sharedcomponent/flayoutmenus';
import CustomDivCourseInside from '../../components/users/coursesuser/customdivcourseinside';
import SideBarCourseInside from '../../components/users/coursesuser/sidebarcourseinside';
import ThreeButtonsInRow from '../../components/users/coursesuser/threebuttonsinrow';
import AboutCourse from '../../components/users/coursesuser/aboutcourse';
import CourseContantSectionInsideCourseDetials from '../../components/users/coursesuser/coursecontantsectioninsidecourse';
import InstructorSection from '../../components/users/coursesuser/instructorsection';
import ReviewsInsideCourse from '../../components/users/coursesuser/reviewsinsidecours';
import MakeAReview from '../../components/users/coursesuser/makereview';
import CustomThingsToDo from '../../components/users/coursesuser/customthingstodo';
import CustomContactInsideCourse from '../../components/users/coursesuser/customcontactinsidecourse';
import ScrollInROWCourseList from '../../components/users/coursesuser/scrollinrowcourselist';
import FooterUser from '../../components/users/sharedcomponent/footeruser';
import CustomNavBarWithFlayout from '../../components/users/sharedcomponent/customnavbarwithflayout';
import img from '../../asset/images/yellorighticon.png';
import { useNavigate } from 'react-router-dom';
import image from '../../asset/images/d1af7d099058eb24786ab692d7f35bbc.png';
import { GlobeAltIcon } from '@heroicons/react/24/outline';
import ListInsideCourses from '../../components/users/coursesuser/listinsidecourse';
import HeaderHomeUserMobile from '../../components/users/sharedcomponent/customMobileHead';
import SideBarCourseInsideMobile from '../../components/users/coursesuser/customSideBarInsideCoursseMobile';
import CustomStaticFlyoutMenu from '../../components/users/sharedcomponent/customFlayoutinStaticHeader';
import ListInsideCoursesMobile from '../../components/users/coursesuser/listInsideCourseMobile';
const CourseInside = () => {
  const navigate = useNavigate();
  const handleButtonClick = (sectionId) => {
    console.log("Button clicked for section:", sectionId); // Log section ID
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  
  const handleClick = () => {
    navigate('/allcourses'); 
  };
  const [isNavBarVisible, setIsNavBarVisible] = useState(false);
  const [activeSection, setActiveSection] = useState('info');
  
  const [isThreeButtonsVisible, setIsThreeButtonsVisible] = useState(false);

  const aboutCourseRef = useRef(null);
  const courseContentRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.scrollY;

      // Update navbar visibility
      setIsNavBarVisible(currentScrollTop > 100);

      // Update active section based on scroll position
      const sections = ['info', 'components', 'Instructor', 'reviews', 'things-to-know'];
      sections.forEach(sectionId => {
        const element = document.getElementById(sectionId);
        if (element) {
          const { offsetTop, clientHeight } = element;
          if (currentScrollTop >= offsetTop && currentScrollTop < offsetTop + clientHeight) {
            setActiveSection(sectionId);
          }
        }
      });

      // Handle visibility of ThreeButtonsInRow
      if (courseContentRef.current) {
        const courseContentTop = courseContentRef.current.offsetTop;
        setIsThreeButtonsVisible(currentScrollTop >= courseContentTop);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    
    <div className="relative">
      <div className={`transition-all duration-300 ${isNavBarVisible ? 'pt-16' : 'pt-0'}`}>
        <HeadUser />
        <HeaderHomeUse />
        <CustomDivider />
        <div className='flex justify-center items-center hidden-mobile'>
          <CustomStaticFlyoutMenu />
        </div>
      </div>
      <CustomDivider />
      <div className={`fixed mobile-only top-0 left-0 w-full bg-white z-50 transition-transform duration-300 ${isNavBarVisible ? 'translate-y-0' : '-translate-y-full'}`}>
    <HeaderHomeUserMobile />
</div>

        <div className={`fixed hidden-mobile top-0 left-0 w-full bg-white z-50 transition-transform duration-300 ${isNavBarVisible ? 'translate-y-0' : '-translate-y-full'}`}>
          <CustomNavBarWithFlayout />
        </div>
      <div className='bg-gray-100 z-0'>
        <CustomDivCourseInside
        navTitle='Courses'
        navTitleTwo='English Popular Course '
        title='ENGLISH POPULAR COURSE'
        onClick={handleClick}
        showReview={true}
      showPrice={true}
        showDate={true}
        showLanguage={true}
        languageIcon={<GlobeAltIcon className="h-4 w-4 text-gray-500" />} // Pass a custom icon
        showDateIcon={true}
        showDateText={true}
        showLanguageIcon={true}
        showLanguageText={true}
        reviewTitle="Tarek Tantawy"
        imageSrc={image}
        price="5000 $"
        date="16 Nov, 2024"
        language="English"
        />
               <div className='mobile-only'>
        <SideBarCourseInsideMobile/>
        </div>
        <div className="flex  
        xxxs:mx-4 xxs:mx-4 xs:mx-4 sm:m-10 lg:m-10 xl:m-10 1xl:m-10 11xl:m-10 2xl:m-10 3xl:m-10
        lg:px-10 xl:px-16 1xl:px-16 11xl:px-16 2xl:px-16 3xl:px-20  ">
      
          <div className="flex-grow ">
     
            {isThreeButtonsVisible && (
              <div className={`transition-transform duration-200 ${isThreeButtonsVisible ? 'fixed top-[80px] z-40' : 'relative'}`}>
                <ThreeButtonsInRow activeSection={activeSection}
                onButtonClick={handleButtonClick} 
                />
              </div>
            )}
                  <ThreeButtonsInRow activeSection={activeSection}
                     onButtonClick={handleButtonClick} 
                  />
            <div id="info" ref={aboutCourseRef}>
              <AboutCourse />
            </div>
            <div id="components" ref={courseContentRef}>
              <CourseContantSectionInsideCourseDetials />
            </div>
            <div className="  xxxs:w-full xxs:w-full xs:w-full xxxxs:w-full xxxxxs:w-full
        sm:w-[600px] lg:w-[600px] xl:w-[700px] 1xl:w-[800px] 11xl:w-[760px] 2xl:w-[900px] 3xl:w-[1100px] 
              ">
            <div
                id='Instructor' className='flex flex-col items-start justify-start mt-5 bg-white p-5'>
                <h3 className="xxxs:text-xl xxs:text-xl xs:text-xl sm:text-3xl lg:text-3xl xl:text-3xl 1xl:text-3xl 11xl:text-3xl 2xl:text-3xl 3xl:text-3xl font-bold text-big-text-color">
                  Instructor</h3>
               <div className='shadow-lg'>
               <InstructorSection />
               </div>
              </div>
            </div>
            {/* <div id='reviews'>
              <ReviewsInsideCourse />
            </div> */}
            <MakeAReview />
            <div id='things-to-know'>
              <CustomThingsToDo
               title='Things To Know'/>
            </div>
            <CustomContactInsideCourse />
          </div>
          <div className=' relative top-[-350px] w-[400px] mb-10 mt-10
           sm:ml-10 lg:ml-10 xl:ml-20 1xl:ml-20 11xl:ml-20 2xl:ml-20 3xl:ml-20 pl-3 hidden-mobile '>
            <div className='sticky top-[80px]'> {/* Adjust top value to match height of CustomNavBarWithFlayout */}
              <SideBarCourseInside />
            </div>
          </div>
        </div>
        <div className="flex  ">
    
 <div className='hidden-mobile'>
 <ListInsideCourses className="m-0 p-0" />
 </div>
  <div className='mobile-only'>
  <ListInsideCoursesMobile />
  </div>
</div>
      </div>
      <FooterUser />
    </div>
  );
};

export default CourseInside;
