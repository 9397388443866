import React from "react";
import DropdownWithHint from "../leadsuser/dropdowncourselist";

export default function AccountSettingForm() {
  // Function to allow only numbers, spaces, parentheses, plus sign, and hyphen
  function isNumber(event) {
    const key = event.keyCode || event.charCode;  // Get keycode of the pressed key
    const keyPressed = String.fromCharCode(key);  // Convert keycode to the character

    // Allow only numbers, spaces, parentheses, plus sign, and hyphen
    const allowedChars = /^[0-9\s\-\(\)\+]$/;

    // If the character pressed doesn't match the allowed characters, block it
    if (!allowedChars.test(keyPressed)) {
      event.preventDefault();  // Prevent the default behavior (key being typed)
    }
  }

  return (
    <div className="xxxs:mx-4 xxs:mx-4 xs:mx-4 sm:mx-20 lg:mx-20
    xl:mx-24 1xl:mx-24 11xl:mx-24 2xl:mx-24 3xl:mx-24 block shadow-lg
     rounded-lg bg-white p-4 ">
      <h3 className="text-2xl font-bold  mb-2 text-big-text-color">
        CONTACT FORM
      </h3>
      <p className="text-sm mb-5 text-big-text-color">
      Simple and easy account settings with customized options
      </p>

      <form>
        {/* Label and Input for First Name and Last Name */}
        <h3 className="text-xl font-bold  mb-5 text-big-text-color">
        Profile Information
      </h3>
        <div className="flex mb-6">
          <div className="w-1/2 pr-2">
            <input
              id="first-name"
              type="text"
              placeholder="first name"
              maxLength="12"
              className="w-full px-4 py-3 text-xs sm:text-sm  border border-gray-300 rounded-lg
                placeholder:text-base 
                xxxs:placeholder:text-sm  xxs:placeholder:text-sm xs:placeholder:text-sm 
                xxxxs:placeholder:text-sm xxxxxs:placeholder:text-sm
                sm:placeholder:text-sm md:placeholder:text-sm lg:placeholder:text-lg"
            />
          </div>
          <div className="w-1/2 pl-2">
            <input
              id="last-name"
              type="text"
              maxLength="12"
              placeholder="last name"
              className="w-full px-4 py-3 text-xs sm:text-sm  border border-gray-300 rounded-lg
                placeholder:text-base 
                xxxs:placeholder:text-sm  xxs:placeholder:text-sm xs:placeholder:text-sm xxxxs:placeholder:text-sm xxxxxs:placeholder:text-sm
                 sm:placeholder:text-sm md:placeholder:text-sm lg:placeholder:text-lg"
            />
          </div>
        </div>
        <div className="flex mb-6">
          <div className="w-1/2 pr-2">
            <input
              id="email"
              type="email"
              placeholder="email"
              className="w-full px-4 py-3 text-xs sm:text-sm  border border-gray-300 rounded-lg
                placeholder:text-base 
                xxxs:placeholder:text-sm  xxs:placeholder:text-sm xs:placeholder:text-sm xxxxs:placeholder:text-sm xxxxxs:placeholder:text-sm
                 sm:placeholder:text-sm md:placeholder:text-sm lg:placeholder:text-lg"
            />
          </div>
          <div className="w-1/2 pl-2">
            <input
              id="phone"
              type="tel"
              placeholder="phone number"
              className="w-full px-4 py-3 text-xs sm:text-lg  border border-gray-300 rounded-lg
                placeholder:text-base 
                xxxs:placeholder:text-sm xxs:placeholder:text-sm xs:placeholder:text-sm xxxxs:placeholder:text-sm xxxxxs:placeholder:text-sm
              sm:placeholder:text-sm md:placeholder:text-sm lg:placeholder:text-lg"
              required
              onKeyPress={isNumber} // React event handler for key press
            />
          </div>
        </div>
        <h3 className="text-xl font-bold  mb-5 text-big-text-color">
        Change Password
              </h3>
      
              <div className="flex mb-6">
          <div className="w-1/2 pr-2">
            <input
              id="Current-Password"
              type="text"
              placeholder="Current Password"
              maxLength="12"
              className="w-full px-4 py-3 text-xs sm:text-sm  border border-gray-300 rounded-lg
                placeholder:text-base 
                xxxs:placeholder:text-sm  xxs:placeholder:text-sm xs:placeholder:text-sm 
                xxxxs:placeholder:text-sm xxxxxs:placeholder:text-sm
                 sm:placeholder:text-sm md:placeholder:text-sm lg:placeholder:text-lg"
            />
          </div>
       
        </div>
        <div className="flex mb-6">
          <div className="w-1/2 pr-2">
            <input
              id="New-Password"
              type="email"
              placeholder="New Password"
              className="w-full px-4 py-3 text-xs sm:text-sm  border border-gray-300 rounded-lg
                placeholder:text-base 
                xxxs:placeholder:text-sm  xxs:placeholder:text-sm xs:placeholder:text-sm xxxxs:placeholder:text-sm xxxxxs:placeholder:text-sm
                 sm:placeholder:text-sm md:placeholder:text-sm lg:placeholder:text-lg"
            />
          </div>
          <div className="w-1/2 pl-2">
            <input
              id="phone"
              type="tel"
              placeholder="Confirm New Password"
              className="w-full px-4 py-3 text-xs sm:text-sm  border border-gray-300 rounded-lg
                placeholder:text-base 
                xxxs:placeholder:text-sm xxs:placeholder:text-sm xs:placeholder:text-sm xxxxs:placeholder:text-sm xxxxxs:placeholder:text-sm
                 sm:placeholder:text-sm md:placeholder:text-sm lg:placeholder:text-lg"
              required
              onKeyPress={isNumber} // React event handler for key press
            />
          </div>
        </div>

        {/* Submit Button */}
        <div className="flex justify-center mb-10">
          <button
            type="submit"
            className="block w-1/2 rounded-lg bg-primary-color
              px-8 py-3 text-base font-medium uppercase
              leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            Sign up
          </button>
        </div>
      </form>
    </div>
  );
}
