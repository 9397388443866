import React from 'react' ;
import MarkWithText from './markwithtext';
const BenefitsCourse =()=>{
    return (
      <div className='xxxs:w-full xxs:w-full xs:w-full xxxxs:w-full xxxxxs:w-full
      sm:w-[600px] lg:w-[600px] xl:w-[700px] 1xl:w-[800px] 11xl:w-[760px] 2xl:w-[900px] 3xl:w-[1100px] 
  bg-white rounded-custom mt-5 p-4'>
              <h3 className='xxxs:text-xl xxs:text-xl xs:text-xl sm:text-3xl lg:text-3xl xl:text-3xl 1xl:text-3xl 11xl:text-3xl 2xl:text-3xl 3xl:text-3xl font-bold pt-2 text-big-text-color '>
              Benefits Of This Course
     </h3>
        <div  >
        <MarkWithText title='Understand and implement React component lifecycle methods for optimal performance.'/>
          <MarkWithText title='Implement seamless navigation for single-page applications using React Router.'/>
          <MarkWithText title='Connect React applications to external APIs, fetching and displaying real-time data.'/>
        </div>
     </div>
    )
}
export default BenefitsCourse;