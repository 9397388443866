import React, { useState } from 'react';
import CustomButton from '../sharedcomponent/custombutton';
import ScrollToggle from './ScrollToggle';
import imageURL from '../../../asset/images/Rectangle 42319.png';
import CustomBigTitle from './custombigtitle';

const MemberShipSectionInHome = () => {
  // State to track which ScrollToggle is open
  const [openIndex, setOpenIndex] = useState(0); // Open the first ScrollToggle by default

  const toggleScroll = (index) => {
    setOpenIndex(openIndex === index ? -1 : index); // Close if already open, otherwise open the clicked one
  };

  return (
    <div className="px-4 py-20 bg-white">
      <div >
        <CustomButton name="Membership" />
      </div>
      <div className='mt-5 mb-10'>
        <CustomBigTitle title='HOW TO SUBSCRIBE BECOME A MEMBER' />
      </div>
      <div className="lg:flex xl:flex 1xl:flex 11xl:flex 2xl:flex 3xl:flex  
       lg:px-20 xl:px-20 1xl:px-20 11xl:px-20 2xl:px-20 3xl:px-20 mx-2 xxxs:px-2 xxs:px-2 xs:px-2">
        <div className="w-full xxxxs:w-full xxxxxs:w-full md:w-full mid:w-full 
         lg:w-1/2  xl:w-1/2 1xl:w-1/2  11xl:w-1/2 2xl:w-1/2  22xl:w-1/2 3xl:w-1/2   ">
          <ScrollToggle
            title='How To Subscribe & Our Fees'
            subtitle='Interior Design Course  '
            isOpen={openIndex === 0}
            onClick={() => toggleScroll(0)}
          />
          <ScrollToggle
            title='Admission Eligibility'
            subtitle='Interior Design Course '
            isOpen={openIndex === 1}
            onClick={() => toggleScroll(1)}
          />
          <ScrollToggle
            title='How To Subscribe & Our Fees'
            subtitle='Interior Design Course '
            isOpen={openIndex === 2}
            onClick={() => toggleScroll(2)}
          />
          <ScrollToggle
            title='How To Subscribe & Our Fees'
            subtitle='Interior Design Course '
            isOpen={openIndex === 3}
            onClick={() => toggleScroll(3)}
          />
        </div>
        <div className="sm:ml-0 lg:ml-24 xl:ml-24 1xl:ml-24 11xl:ml-24 2xl:ml-24 3xl:ml-24 flex 
        justify-start items-start">
          <img
            src={imageURL}
            alt="Membership Info"
            className='xxxs:h-[300px] xxs:h-[300px] xs:h-[300px] xxxs:w-full xxs:w-full xs:w-full
             lg:h-[550px] lg:w-[550px] xl:h-[540px] xl:w-[550px]
             1xl:h-[540px] 1xl:w-[550px] 11xl:h-[540px] 11xl:w-[550px]
              2xl:h-[550px] 2xl:w-[650px]  22xl:h-[550px] 22xl:w-[650px] 3xl:h-[650px] 3xl:w-[750px]'
          />
        </div>
      </div>
    </div>
  );
};

export default MemberShipSectionInHome;

