import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StarReview from "../../admin/reviews";
import { ShoppingCartIcon, BookmarkIcon } from "@heroicons/react/24/outline";
import imageShop from '../../../asset/images/store.png';
import img from '../../../asset/images/yellorighticon.png';
import { useNavigate } from 'react-router-dom';
import CustomParagraph from "../sharedcomponent/customParagraph";
import BookmarkButton from '../sharedcomponent/customBookMark';
const products = [
  {
    id: 1,
    name: 'Basic Tee',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    priceBefore: '$50',
    color: 'Black',
  },
  {
    id: 2,
    name: 'Basic Tee',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    priceBefore: '$50',
    color: 'Black',
  },
];

export default function AllShopsGrid() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/storeInside'); 
  };
  return (
    <div className="flex w-full mx-auto  xxxs:px-0 xxs:px-0 xs:px-0 
     sm:px-6 lg:px-8 xl:px-2 1xl:px-2  11xl:px-2  2xl:px-2 22xl:px-2 3xl:px-2">
    <div className="w-full max-w-screen-2xl mx-auto">
      <div className="grid grid-cols-1 gap-x-8 gap-y-12 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 
      11xl:grid-cols-3 1xl:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-4">
        {products.map((product) => (
 <div key={product.id} 
 className="group relative xxxs:w-full xxs:w-full xs:w-full sm:w-full 
            lg:w-[240px] xl:w-[240px] 11xl:w-[250px] 1xl:w-[260px] 2xl:w-[310px]
             22xl:w-[350px]
              3xl:w-[300px] lg:mx-0 xl:mx-0 2xl:mx-10 xxxs:2xl:mx-0 xxs:mx-0 xs:mx-0 sm:mx-0  md:mx-0 mid:mx-0 p-3
   bg-white rounded-lg shadow-lg overflow-hidden transition-transform duration-300 transform hover:scale-105">
      
        <h3 
  className="text-xl font-semibold text-center items-center justify-center text-gray-900 cursor-pointer" 
  onClick={handleClick} // Replace 'handleClick' with your actual function
>
  {product.name}
</h3>

              <h4 className="text-sm font-semibold leading-6 tracking-tight text-big-text-color text-center">{product.name}</h4>
              <div className="w-full">
  <img
    src={imageShop}
    alt={imageShop}
    className="w-full h-40 object-center object-cover mb-2"
  />
</div>

              <div className='flex justify-between items-center w-full mb-2 pr-2 '>
                <StarReview />
                <BookmarkButton/>
              </div>
              <CustomParagraph
              title=' Research your target market and understand their needs and preferences. your target market and understand their needs.'
              />
              <div className="flex items-center justify-between w-full pr-2 pl-2">
                <h3 className="text-xl font-semibold leading-8 tracking-tight text-yellow-600">
                  {product.price}
                </h3>
                <button
                onClick={handleClick}
                className="mt-2 mb-2 text-base text-custom-border border rounded-custom border-custom-border bg-transparent p-2 flex items-center hover:bg-gray-100 transition-colors items-center justify-center">

Add To Cart
<img
src={img}
alt="Course Icon"
className="ml-2 justify-center items-center"
/>
</button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
