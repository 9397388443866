import React from 'react';
import { FiPlus, FiMinus } from 'react-icons/fi';

const ScrollToggle = ({ title, subtitle, isOpen, onClick, index }) => {
  const handleToggle = () => {
    onClick(index); // Pass the index to the parent to toggle the correct item
  };

  return (
    <div className="flex flex-col items-center w-full">
      <button
        onClick={handleToggle}
        className={`flex items-center justify-between p-4 rounded-lg shadow-md hover:bg-gray-100
           focus:outline-none focus:ring-2 focus:ring-offset-2
            focus:ring-gray-200 w-full max-w-4xl border-none
          ${isOpen ? 'bg-[#F7F6F4]' : 'bg-[#F7F6F4]'}`}
      >
        <span
          className={`text-lg ${isOpen ? 'text-primary-color' : 'text-big-text-color'}`}
        >
          {title}
        </span>
        {isOpen ? (
          <FiMinus
            size={24}
            className={`text-lg ${isOpen ? 'text-primary-color' : 'text-big-text-color'}`}
          />
        ) : (
          <FiPlus
            size={24}
            className={`text-lg ${isOpen ? 'text-yellow-500' : 'text-big-text-color'}`}
          />
        )}
      </button>

      <div
        id={`scroll-target-${index}`} // Unique ID for each ScrollToggle
        className={`transition-all duration-500 ease-in-out mt-2 mb-4 w-full max-w-4xl ${
          isOpen ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'
        } overflow-hidden`}
      >
        <div className="p-4 bg-[#F7F6F4] rounded-lg shadow-inner">
          <p className="text-big-text-color p-2">{subtitle}</p>
          <div className="flex justify-between bg-[#F0E0C2] py-2 px-2">
            <h2 className="text-lg">How to subscribe & our fees</h2>
            <h2 className="text-lg">Total</h2>
          </div>

          <div className="flex justify-between py-4 px-2">
            <h2 className="text-lg">How to subscribe & our fees</h2>
            <h2>541$</h2>
          </div>

          <div className="flex justify-between bg-[#D9D9D9] py-2 px-2">
            <h2 className="text-lg">How to subscribe & our fees</h2>
            <h2>541$</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScrollToggle;

