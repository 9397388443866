import React ,{useEffect,useState ,useRef} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import StarReview from '../../admin/reviews';
import imageTeamOne from '../../../asset/images/team-1.webp.png';
import imageTeamTwo from '../../../asset/images/team-02.webp.png';
import imageTeamThree from '../../../asset/images/team-03.webp.png';
import imagecoursethree from '../../../asset/images/istock-1220226086.jpg'
import imageCourseTwo from '../../../asset/images/Capstone_Course.jpeg.webp';
import CustomIconAndText from '../sharedcomponent/customiconandtext';
import { CreditCardIcon, UsersIcon, BookmarkIcon } from '@heroicons/react/24/outline';
import CustomTitleAndImageOnly from '../sharedcomponent/imageandtitleonly';
import CustomButton from '../sharedcomponent/custombutton';
import imagecourseone from '../../../asset/images/events.png';
import CustomBigTitle from './custombigtitle';
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import { Button } from '@headlessui/react';
import BookmarkButton from '../sharedcomponent/customBookMark';
import CustomParagraph from '../sharedcomponent/customParagraph';
const products = [
  {
    id: 1,
    name: 'INSTRUCTIONAL ',
    href: '#',
    imageTeam : imageTeamOne,
    imageSrc : imagecourseone,
    studentNum : '22',
    courseNum : '44',
    contant : 'This course offers a education to the principles and practices of instructional design, aimed at equipping students with the skills offers a comprehensive introduction.',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$84',
    color: 'Black',
  },
  {
    id: 2,
    name: 'UI DESIGN',
    href: '#',
    studentNum : '65',
    courseNum : '87',
    imageTeam : imageTeamTwo,
    imageSrc : imagecoursethree,
    contant : 'This course offers the principles and practices of instructional design, aimed at equipping students with the skills offers a comprehensive introduction.',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$98',
    color: 'Black',
  },
  {
    id: 3,
    name: 'Frontend DESIGN',
    href: '#',
    studentNum : '88',
    courseNum : '12',
    imageTeam : imageTeamThree,
    imageSrc : imageCourseTwo,
    contant : 'This course offers a common course  to the principles and practices of instructional design, aimed at equipping students with the skills offers a comprehensive introduction.',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$85',
    color: 'Black',
  },
  {
    id: 1,
    name: 'DESIGN',
    href: '#',
    studentNum : '85',
    courseNum : '14',
    imageTeam : imageTeamOne,
    imageSrc : imagecourseone,
    contant : 'This course offers a education to the principles and practices of instructional design, aimed at equipping students with the skills offers a comprehensive introduction.',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$65',
    color: 'Black',
  },
  {
    id: 2,
    name: 'INSTRUCTIONAL DESIGN',
    href: '#',
    studentNum : '22',
    courseNum : '44',
    imageTeam : imageTeamTwo,
    imageSrc : imageCourseTwo,
    contant : 'This course offers the principles and practices of instructional design, aimed at equipping students with the skills offers a comprehensive introduction.',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$55',
    color: 'Black',
  },
  {
    id: 3,
    name: 'INSTRUCTIONAL DESIGN',
    href: '#',
    studentNum : '22',
    courseNum : '44',
    imageTeam : imageTeamThree,
    imageSrc : imagecoursethree,
    contant : 'This course offers a education to the principles and practices of instructional design, aimed at equipping students with the skills offers a comprehensive introduction.',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$41',
    color: 'Black',
  },
];

export default function ListOfCourseHome() {
  const scrollRef = useRef(null);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/courseInside");
  };

  const scrollOneItem = (direction) => {
    if (scrollRef.current) {
      const itemWidth = scrollRef.current.children[0].offsetWidth;
      const scrollAmount = direction === "left" ? -itemWidth : itemWidth;
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  return (
    <div className="bg-transparent justify-center items-center px-2 pb-5">
      <div className=" justify-center items-center xxxs:px-10
       xxs:px-10 xs:px-10 xxxxs:px-10 xxxxxs:px-10 lg:px-24">
        <div className="relative flex items-center">
          {/* Left Arrow */}
          <button
            onClick={() => scrollOneItem("left")}
            className="absolute left-[-50px] z-10 p-2 text-white bg-primary-color rounded-full shadow-md
              focus:outline-none"
            style={{ top: "50%", transform: "translateY(-50%)" }}
          >
            <ArrowLeftIcon className="w-6 h-6" />
          </button>

          {/* Scrollable Container */}
          <div
            ref={scrollRef}
            className="flex overflow-x-auto sm:p-4 w-full scrollbar-hidden space-x-3"
            style={{ scrollSnapType: "x mandatory" }}
          >
            {products.map((product) => (
              <div
                key={product.id}
                className="relative flex-none w-full sm:w-1/2 md:w-1/3
                 lg:w-1/3 xl:w-1/3 min-w-[100px] lg:min-w-[25%] rounded-lg shadow-sm bg-white p-4"
                style={{ scrollSnapAlign: "start" }}
              >
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md
                 bg-white lg:aspect-none group-hover:opacity-75 lg:h-40  ">
                  <img
                    alt={product.imageAlt}
                    src={product.imageSrc}
                    className="h-full w-full object-cover object-center"
                  />
                </div>
                <div className="mt-2">
                  <div className="flex justify-between">
                    <StarReview />
                    <BookmarkButton />
                  </div>
                  <h2 className="text-lg font-semibold mt-2 text-big-text-color">
                    {product.name}
                  </h2>
                  <div className="flex space-x-4 mt-2">
                    <CustomIconAndText
                      icon={
                        <CreditCardIcon className="h-4 w-4 text-gray-500" />
                      }
                      title={`${product.courseNum} Courses `}
                    />
                    <CustomIconAndText
                      icon={<UsersIcon className="h-4 w-4 text-gray-500" />}
                      title={`${product.studentNum} Students `}
                    />
                  </div>
                  <CustomParagraph title={product.contant} />
                  <CustomTitleAndImageOnly
                    imageSrc={product.imageTeam}
                    title="Tarek Tantawy"
                  />
                  <div className="mt-2 mb-2 flex justify-between items-center">
                    <p className="text-lg font-bold text-primary-color">
                      {product.price}
                    </p>
                    <button
                      onClick={handleClick}
                      className="mt-1 text-sm text-gray-500 border
                       rounded-custom border-gray-400 bg-transparent px-2 py-1 flex items-center"
                    >
                      ENROLL COURSE
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Right Arrow */}
          <button
            onClick={() => scrollOneItem("right")}
            className="absolute right-[-50px] z-10 p-2 text-white bg-primary-color rounded-full
             shadow-md  focus:outline-none"
            style={{ top: "50%", transform: "translateY(-50%)" }}
          >
            <ArrowRightIcon className="w-6 h-6" />
          </button>
        </div>
      </div>
    </div>
  );
}