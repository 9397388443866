import React, { useState } from 'react';

const SortByDropdownMobile = ({ onSort }) => {
  const [selectedOption, setSelectedOption] = useState('');

  const handleChange = (e) => {
    setSelectedOption(e.target.value);
    onSort(e.target.value); // Trigger the sorting action on selection
  };

  return (
 <div>
       <h3 className='text-sm font-bold px-2 text-big-text-color mb-1'>
    Sort By
    </h3>
    <div className="flex w-[180px] items-center justify-center 
    border border-big-text-color rounded-custom ml-2"> 
    {/* Adjusted ml-2 for left margin */}
      <select
        value={selectedOption}
        onChange={handleChange}
        className="py-2 px-4 placeholder-big-text-color left-[40px]
        text-big-text-color bg-transparent rounded-custom focus:outline-none"
      >
        <option value="" disabled className="bg-white text-black">
          Sort By
        </option>
        <option value="name" className="bg-white text-black">
          Name
        </option>
        <option value="date" className="bg-white text-black">
          Date
        </option>
        <option value="rating" className="bg-white text-black">
          Rating
        </option>
        <option value="price" className="bg-white text-black">
          Price
        </option>
      </select>
    </div>
 </div>
  );
};

export default SortByDropdownMobile;
