import React from 'react';
import { ChevronDoubleRightIcon } from '@heroicons/react/24/solid';

const CustomDivCheckout = () => {
  return (
    <div className=' justify-start items-start
     pt-4 pb-4 xxxs:px-10 xxs:px-10 xs:px-10 sm:px-20 lg:px-28 xl:px-28 1xl:px-28 
     11xl:px-28 2xl:px-28 3xl:px-28 bg-custom-gradient-div min-h-[300px] lg:min-h-[350px] text-white'>
      <div className='flex items-start mb-10'>
        <h3 className='text-lg text-big-text-color mr-3'>Home</h3>
        <ChevronDoubleRightIcon className='w-8 h-8 mr-3' />
        <h3 className='text-lg text-big-text-color'>checkout</h3>
      </div>
      <h3 className='text-3xl font-bold text-big-text-color'>checkout</h3>
   
    </div>
  );
};

export default CustomDivCheckout;