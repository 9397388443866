import React, { useState } from 'react';

const MakeAReview = () => {
  const [activeButton, setActiveButton] = useState(null);

  const handleButtonClick = (button) => {
    setActiveButton(button);
  };

  return (
    <div className="xxxs:w-full xxs:w-full xs:w-full xxxxs:w-full xxxxxs:w-full
        sm:w-[600px] lg:w-[600px] xl:w-[700px] 1xl:w-[800px] 11xl:w-[760px] 2xl:w-[900px] 3xl:w-[1100px] 
    bg-white rounded-custom mt-5 p-4 relative">
      <h3 className="xxxs:text-xl xxs:text-xl xs:text-xl sm:text-3xl lg:text-3xl xl:text-3xl 1xl:text-3xl 11xl:text-3xl 2xl:text-3xl 3xl:text-3xl font-bold text-big-text-color">
        Make a Review
      </h3>
      <div className="flex justify-center items-center space-x-4 mt-2">
        <button
          className={`font-bold rounded text-base flex items-center justify-center
             transition duration-300 ease-in-out transform hover:scale-105
            focus:outline-none focus:ring-2 focus:ring-offset-2 z-0 px-6 py-2
            ${activeButton === 'signUp' ? 'bg-primary-color text-white' : 'bg-transparent text-black border-2 border-black'}`}
          onClick={() => handleButtonClick('signUp')}
        >
          Sign Up
        </button>
        <button
          className={`font-bold rounded-lg text-base flex items-center px-8 py-2
            justify-center transition duration-300 ease-in-out transform hover:scale-105
            focus:outline-none focus:ring-2 focus:ring-offset-2
            ${activeButton === 'login' ? 'bg-primary-color text-white' : 'bg-transparent text-black border-2 border-black'}`}
          onClick={() => handleButtonClick('login')}
        >
          Login
        </button>
      </div>
    </div>
  );
};

export default MakeAReview;
