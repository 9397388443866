import React, { useState, useEffect ,useRef } from 'react';
import Logo from '../../../asset/images/BIS_png_1.png';
import SearchBarHomeUser from './searchbarhomeuser';
import CustomTitleAndImage from './customtitleandimage';
import image from '../../../asset/images/Ellipse 82.png';
import { BellIcon, UserIcon, ShoppingCartIcon, Bars4Icon, XMarkIcon ,MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { Disclosure, Button, Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/react';
import ShadowButton from './buttonshadow';
import { Link, useLocation } from 'react-router-dom';
import ListInCart from '../../users/homeuser/listincart';
import ListInBell from '../../users/homeuser/listofbell';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faTiktok, faLinkedinIn, faWhatsapp ,faYoutube,faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import CustomProfileAndImageOnly from './customprofilandtitle';
import { useNavigate } from 'react-router-dom';

const menuData = {
    Courses: {
        footerText: 'Explore our Courses',
        footerLinks: [
            { name: 'Approach To Learn', link: '#' },
            { name: 'Training Locations', link: '#' },
            { name: 'Registration Method', link: '#' },
            { name: 'Talk to Specialist', link: '#' },
            { name: 'FAQs', link: '#' },
        ],
        sections: [
            {
                id: 1,
                headline1: 'Find Course',
                subItems: [
                    { name: 'Course Home', link: '/allcourses' },
                    { name: 'Course Categories', link: '#' },
                    { name: 'Explore By Role', link: '#' },
                    { name: 'Explore By Levels', link: '#' },
                    { name: 'Search All Courses', link: '#' },
                ]
            },
            {
                id: 2,
                headline1: 'Delivery Methods',
                subItems: [
                    { name: 'Offline', link: '#' },
                    { name: 'Online', link: '#' },
                    { name: 'Face To Face', link: '#' },
                    { name: 'On Demand', link: '#' },
                    { name: 'Corporate Training', link: '#' }
                ]
            },
            {
                id: 3,
                headline1: 'Get Certified',
                subItems: [
                    { name: 'Certification Home', link: '#' },
                    { name: 'Why get Certification?', link: '#' },
                    { name: 'Certification Portal', link: '#' },
                    { name: 'Recertification', link: '#' },
                ]
            },
        ]
    },
    Resources: {
        footerText: 'Explore our Resources',
        footerLinks: [
            { name: 'Order information', link: '/allresourses' },
            { name: 'Rights & Permissions', link: '#' },
        ],
        sections: [
            {
                id: 1,
                headline1: 'Books',
                subItems: [
                    { name: 'Books Home', link: '#' },
                    { name: 'Browse All Books', link: '#' },
                ]
            },
            {
                id: 2,
                headline1: 'Research',
                subItems: [
                    { name: 'Research Home', link: '#' },
                    { name: 'Browse All Reports', link: '#' },
                ]
            },
            {
                id: 3,
                headline1: 'Booklet',
                subItems: [
                    { name: 'Booklet Home', link: '#' },
                    { name: 'Browse All Files', link: '#' },
                ]
            },
        ]
    },
    Store: {
        footerText: 'Shop at our Store',
        footerLinks: [
            { name: 'Join Today', link: '#' },
            { name: 'Contact Us', link: '#' },
            { name: 'Downloads', link: '#' },
        ],
        sections: [
            {
                id: 1,
                headline1: 'Shop',
                subItems: [
                    { name: 'Store Home', link: '/allshops' },
                    { name: 'Suppliers', link: '#' },
                    { name: 'See all Merchandise', link: '#' }
                ]
            },
            {
                id: 2,
                headline1: 'Store Resources',
                subItems: [
                    { name: 'Merchandise', link: '#' },
                    { name: 'Articles', link: '#' },
                    { name: 'Offers', link: '#' },
                    { name: 'Courses', link: '#' },
                    { name: 'Certifications', link: '#' },
                    { name: 'Membership', link: '#' },
                    { name: 'Webinar', link: '#' }
                ]
            },
        ]
    },
    Membership: {
        footerText: 'Join our Membership',
        footerLinks: [
            { name: 'Refer Your Friend', link: '/allmembership' },
            { name: 'Support (Live)', link: '#' },
            { name: 'FAQs', link: '#' },
            { name: 'Training Locations', link: '#' },
        ],
        sections: [
            {
                id: 1,
                headline1: 'About Membership',
                subItems: [
                    { name: 'Convince Your Boss', link: '#' },
                    { name: 'Forum Membership', link: '#' },
                    { name: 'Membership Options', link: '#' },
                    { name: 'Join Today', link: '#' }
                ]
            },
            {
                id: 2,
                headline1: 'For Member',
                subItems: [
                    { name: 'Benefits Center', link: '#' },
                    { name: 'Member Toolkit', link: '#' },
                    { name: 'Renew Now', link: '#' }
                ]
            },
        ]
    },
    Events: {
        footerText: 'Upcoming Events',
        footerLinks: [
            { name: 'About Us', link: '#' },
            { name: 'Advertise With Us ', link: '#' },
            { name: 'Contact Us ', link: '#' },
            { name: 'Career Hub', link: '#' },

        ],
        sections: [
            {
                id: 1,
                headline1: 'Event Home',
                subItems: [
                    { name: 'Business Index Sentinents Event', link: '/allevents' },
                    { name: 'Global Events', link: '#' },
                    { name: 'See All Events', link: '#' }
                ]
            },
            {
                id: 2,
                headline1: 'Opportunities',
                subItems: [
                    { name: 'Speaking Opportunities', link: '#' },
                    { name: 'Sponsorship Opportunities', link: '#' },
                ]
            },
        ]
    },
    Blog: {
        footerText: 'Read our Blog',
        footerLinks: [
            { name: 'Order information', link: '#' },
            { name: 'Rights & Permissions', link: '#' },
        ],
        sections: [
            {
                id: 1,
                headline1: 'Latest',
                subItems: [
                    { name: 'Podcasts', link: '#' },
                    { name: 'Blog Home', link: '#' },
                    { name: 'Videos', link: '#' },
                    { name: 'Articles', link: '#' },
                    { name: 'Webinars', link: '#' },
                    { name: 'Blogs', link: '#' },
                    { name: 'Conference', link: '#' },
                    { name: 'See all Blogs', link: '#' }
                ]
            },
            {
                id: 2,
                headline1: 'Topics',
                subItems: [
                    { name: 'Career Development', link: '#' },
                    { name: 'Coaching', link: '#' },
                    { name: 'Training & Facilities', link: '#' },
                    { name: 'All Topics', link: '#' }
                ]
            },
        ]
    },
    'About/Contact': {
        footerText: 'About and Contact Us',
        footerLinks: [
            { name: 'Refer Your Friend', link: '#' },
            { name: 'Support (Live)', link: '#' },
            { name: ' FAQs', link: '#' },
            { name: 'Training Locations', link: '#' },
        ],
        sections: [
            {
                id: 1,
                headline1: 'About',
                subItems: [
                    { name: 'About Home', link: '#' },
                    { name: 'Board Of Directors', link: '#' },
                    { name: 'Awards', link: '#' },
                    { name: 'Press Room', link: '#' },
                    { name: 'Clients', link: '#' },
                    { name: 'Our Services', link: '#' }
                ]
            },
            {
                id: 2,
                headline1: 'Contact',
                subItems: [
                    { name: 'Reviews', link: '#' },
                    { name: 'Support (Live)', link: '#' },
                    { name: 'Submit a Request', link: '#' },
                    { name: 'Refer a Friend', link: '#' },
                    { name: 'Career Hub', link: '#' },
                    { name: 'Contact Us', link: '#' }
                ]
            },
        ]
    }
};
const HeaderHomeUserMobile = () => {
    const location = useLocation();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isBellDrawerOpen, setIsBellDrawerOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isOpen, setIsOpen] = useState({});
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 840);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const navigate = useNavigate();

    const handleClickCart = () => {
      navigate('/CheckoutUser'); 
    };
    const logoStyle = {
        height: isMobile ? '40px' : '60px',
        width: isMobile ? '100px' : '200px',
    };

    const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);
    const toggleBellDrawer = () => setIsBellDrawerOpen(!isBellDrawerOpen);

    const iconSize = isMobile ? 'w-5 h-5' : 'w-6 h-6';

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
                setIsOpen({});
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const toggleDropdown = (item) => {
        setIsOpen(prev => {
            const newState = {};
            Object.keys(menuData).forEach(key => {
                newState[key] = key === item ? !prev[key] : false; // Close other dropdowns
            });
            return newState;
        });
    };

    const closeDropdowns = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen({});
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', closeDropdowns);
        return () => {
            document.removeEventListener('mousedown', closeDropdowns);
        };
    }, []);

    return (
        <Disclosure as="nav">
            <div className="w-screen xs:px-8 xxs:px-8 xxxs:px-8  py-2 z-10 bg-white">
                <div className="flex items-center justify-between ">
                    <div className="flex-shrink-0">
                        <Link to="/">
                        <img style={logoStyle} src={Logo} alt="Logo" />
                </Link>
                    </div>

                 
                    <div className='flex items-center space-x-4'>
                    {location.pathname === '/searchview' ? (
                    <Link to='/'>
    <XMarkIcon className={`${iconSize} m-1 cursor-pointer text-big-text-color`} />
    </Link>
                ) : (
                    <Link to='/searchview'>
      <MagnifyingGlassIcon className={`${iconSize} m-1 cursor-pointer text-big-text-color`} />
      </Link>
                )}
                        <Button className='ml-2' onClick={toggleBellDrawer}>
                            <BellIcon className={`${iconSize} text-big-text-color`} />
                        </Button>
                        
                        <button onClick={toggleDrawer}>
                            <ShoppingCartIcon className={`${iconSize} text-big-text-color`} />
                        </button>

                        <Menu as="div" className="relative ml-2 mr-2">
                            <MenuButton className="flex items-center text-gray-700">
                                <UserIcon aria-hidden="true" className={`${iconSize} text-big-text-color`} />
                                <div className='head-user-container'>
                                    <span className={`ml-2 hidden md:inline text-big-text-color`}>Admin</span>
                                </div>
                            </MenuButton>
                            <MenuItems className="absolute right-0 z-50 mt-2 w-48 origin-top-right rounded-md bg-white p-2 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none">
                                <MenuItem>
                                    <CustomProfileAndImageOnly title='Tarek Tantawy' subtitle='Edit Profile' imageSrc={image} />
                                </MenuItem>
                                <MenuItem>
                                    <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                        My Bookmarks
                                    </a>
                                </MenuItem>
                                <MenuItem>
                                    <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                        My Account
                                    </a>
                                </MenuItem>
                                <MenuItem>
                                    <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                        My Orders
                                    </a>
                                </MenuItem>
                                <MenuItem>
                                    <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                        Help
                                    </a>
                                </MenuItem>
                                <hr />
                                <MenuItem>
                                    <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                        Sign out
                                    </a>
                                </MenuItem>
                            </MenuItems>
                        </Menu>
                        {isMobile && (
    <Button onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
        <Bars4Icon className={`${iconSize} text-big-text-color`} />
    </Button>
)}

{/* Dropdown Drawer */}
{isDropdownOpen && (
    <div className="fixed inset-0 flex z-50 justify-end h-screen">
        {/* Backdrop - transparent with blur effect */}
        <div
            className="absolute inset-0 bg-black bg-opacity-10 backdrop-blur-lg"
            onClick={() => setIsDropdownOpen(false)}
        ></div>

        {/* Dropdown Panel */}
        <div
            className={`bg-white shadow-lg transition-transform transform ${
                isDropdownOpen ? "translate-x-0" : "translate-x-full"
            } w-[75vw] h-full`}
        >
            <div className="h-full p-6">
                <div className="flex justify-between items-center">
                    <Link to="/">
                        <img
                            src={Logo}
                            alt="Logo"
                            className="xxx:w-[100px] xxx:h-[40px] sm:w-[170px] sm:h-[60px] mb-4"
                        />
                    </Link>
                    <button onClick={() => setIsDropdownOpen(false)}>
                        <XMarkIcon className="w-6 h-6 text-red" />
                    </button>
                </div>
             
                <p className="mb-3 text-sm text-big-text-color">
                    At Business Index Sentiments, we seek to meet the aspirations of our customers, and to be where their eyes are most visible.
                </p>
                <p className="text-sm text-gray-500 mb-3">
                    <span className='font-bold'>Phone:</span>
                    <a href="tel:+20125478965" className="text-gray-500 hover:underline">+20125478965</a>
                </p>
                <p className="text-sm text-gray-500 mb-3">
                    <span className='font-bold'>E-mail:</span>
                    <a href="mailto:info@example.com" className="text-gray-500 hover:underline">info@example.com</a>
                </p>
                {/* Dropdown Content */}
                <ul className="list-none">
                    {Object.keys(menuData).map((key) => (
                        <li key={key} className="mb-1">
                            {/* Menu Item with Toggle */}
                            <div
                                className="flex justify-between items-center px-2
                                 font-bold hover:bg-gray-200 cursor-pointer"
                                onClick={() => toggleDropdown(key)}
                            >
                               <span className='text-big-text-color'>{key}</span>
                                {isOpen[key] ? (
                                    <span className="text-lg font-bold text-big-text-color">-</span>
                                ) : (
                                    <span className="text-lg font-bold text-big-text-color">+</span>
                                )}
                            </div>

                            {/* Expanded Content */}
                            {isOpen[key] && (
    <div className="pl-4">
        {/* Flex container to show sections in a row */}
        <div className=""> {/* space-x-6 gives space between items */}
        <div className="grid grid-cols-2 gap-6 mt-2"> {/* grid-cols-2 for two columns, gap-6 for spacing between items */}
        {menuData[key].sections.map((section) => (
            <div key={section.id} className="flex flex-col">
                <div className="font-semibold text-sm mb-1">
                    {section.headline1}
                </div>
                {section.subItems.map((subItem) => (
                    <a
                        key={subItem.name}
                        href={subItem.link}
                        className="block text-sm text-gray-700 hover:underline"
                    >
                        {subItem.name}
                    </a>
                ))}
            </div>
        ))}
    </div>
        </div>

        {/* Quick Links in a horizontal row */}
        <div className="mt-4 p-2 bg-gray-100 rounded">
    <h3 className="font-semibold text-sm">Quick Links:</h3>
    {/* Using grid layout to split links into two columns */}
    <div className="grid grid-cols-2 gap-2 mt-2"> {/* grid-cols-2 splits into two columns, gap-2 adds space between */}
        {menuData[key].footerLinks.map((link, index) => (
            <a
                key={index}
                href={link.link}
                className="text-xs text-blue-500 hover:underline"
            >
                {link.name}
            </a>
        ))}
    </div>
</div>

    </div>
)}

                        </li>
                    ))}
                </ul>
                <div className='mt-5'>
                    <ShadowButton children='Become a Member' />
                </div>
                <div className='mt-5'>
                    <h3>Find Us</h3>
                    <div className="flex items-center">
                        <a href="mailto:example@example.com" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faEnvelope} className='text-primary-color mr-2' />
                        </a>
                        <a href="https://wa.me/" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faWhatsapp} className='text-primary-color ' />
                        </a>
                        <a href="https://www.youtube.com/channel/YourChannelID" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faXTwitter} className="text-primary-color ml-2" />
                        </a>
                        <a href="https://www.youtube.com/channel/YourChannelID" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faYoutube} className="text-primary-color ml-2 mr-2" />
                        </a>
                        <a href="https://facebook.com/" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faFacebookF} className='text-primary-color mr-2' />
                        </a>
                        <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faInstagram} className='text-primary-color mr-2' />
                        </a>
                        <a href="https://tiktok.com/" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faTiktok} className='text-primary-color mr-2' />
                        </a>
                        <a href="https://linkedin.com/" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faLinkedinIn} className='text-primary-color mr-2' />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
)}

                        
                        <div className='head-user-container'>
                            <ShadowButton children='My Business' />
                        </div>
                    </div>
                </div>
            </div>

            {/* Shopping Cart Drawer */}
            {isDrawerOpen && (
                <div className="fixed inset-0 flex z-50 justify-center">
                    <div className="absolute inset-0 bg-black opacity-50" onClick={toggleDrawer}></div>
                    <div className={`bg-white shadow-lg transition-transform transform ${isDrawerOpen ? 'translate-x-0' : 'translate-x-full'} w-full`}>
                        <div className="h-full p-8">
                            <div className='flex justify-between mt-10'>
                                <h2 className="text-3xl text-big-text-color font-bold">Shopping Cart</h2>
                                <Link  onClick={toggleDrawer}>
                                    <XMarkIcon className='w-6 h-6 m-1 cursor-pointer text-red' />
                                </Link>
                            </div>
                            <hr className="px-8 mt-5" />
                            <div className='h-[200px] mt-10'>
                                <ListInCart />
                            </div>
                            <div className='flex justify-between mt-5'>
                                <h3 className='text-lg text-big-text-color font-bold'>SubTotal:</h3>
                                <h3 className='text-lg text-big-text-color'>124$</h3>
                            </div>
                            <div className="grid place-items-center mt-4">
                                <button
                                    onClick={toggleDrawer}
                                    className="bg-primary-color text-white px-6 py-2 rounded text-lg w-full"
                                >
                                    Checkout
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Bell Drawer */}
            {isBellDrawerOpen && (
                <div className="fixed inset-0 flex z-50 justify-center">
                    <div className="absolute inset-0 bg-black opacity-50" onClick={toggleBellDrawer}></div>
                    <div className={`bg-white shadow-lg transition-transform transform ${isBellDrawerOpen ? 'translate-x-0' : 'translate-x-full'} w-full`}>
                        <div className="h-full p-8">
                            <div className='flex justify-between mt-10'>
                                <h2 className="text-3xl text-big-text-color font-bold">Notifications</h2>
                                <Link  onClick={toggleBellDrawer}>
                                    <XMarkIcon className='w-6 h-6 m-1 cursor-pointer text-red' />
                                </Link>
                            </div>
                            <hr className="px-8 mt-5" />
                            <div className='mt-10 h-full'>
                                <ListInBell />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Disclosure>
    );
};

export default HeaderHomeUserMobile;

