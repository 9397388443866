import React, { useState } from 'react';
import CustomResourse from '../resoursesuser/customrescoure';
import CustomBigTitle from './custombigtitle';
import AllResourcesListInHome from './listofresoursesinhome';
import CustomButton from '../sharedcomponent/custombutton';
const ResoursesSectionInHome = () => {
  const [activeButton, setActiveButton] = useState('Books');

  const handleButtonClick = (button) => {
    setActiveButton(button);
  };

  return (
    <div className=' justify-center items-center '>
      <div className='flex items-center justify-center pt-10 '>
     <CustomButton name='Resources'/>
      </div>

     <CustomBigTitle title='provide materials that help you to find answers '/>
      <div className='flex justify-center items-center mb-5 mt-5'>
        {['Books', 'Research', 'Booklet'].map((button) => (
          <button
            key={button}
            type="button"
            onClick={() => handleButtonClick(button)}
            className={`xxxs:mr-2 xxxs:ml-2 xxs:mr-2 xxs:ml-2 xs:mr-2 xs:ml-2 sm:mr-5 sm:ml-5
               lg:mr-5 lg:ml-5 xl:mr-5 xl:ml-5 1xl:mx-5 11xl:mx-5 2xl:mx-5 3xl:mr-5 3xl:ml-5 
               inline-block rounded-custom border-2 xxxs:px-1 xxs:px-1 xs:px-1
               sm:px-6 lg:px-6 xl:px-6 1xl:px-6 11xl:px-6 2xl:px-6 3xl:px-6 pb-[6px] pt-2
                text-xs font-medium uppercase leading-normal transition duration-150 ease-in-out 
              ${activeButton === button ? 'border-primary-color bg-primary-color text-white' : 'border-primary-color text-primary-color hover:border-info-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-info-600 focus:border-info-600 focus:text-info-600 focus:outline-none focus:ring-0 active:border-info-700 active:text-info-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10'}`}
          >
            {button}
          </button>
        ))}
      </div>
      <div className='flex'>
        {activeButton === 'Books' && (
          <>
           <div className='w-full '>
           <AllResourcesListInHome />
           </div>
          </>
        )}
        {activeButton === 'Research' && (
          <>
          <div className='w-full '>
          <AllResourcesListInHome />
          </div>
         </>
        )}
        {activeButton === 'Booklet' && (
         <>
         <div className='w-full '>
         <AllResourcesListInHome />
         </div>
        </>
        )}
      </div>
    </div>
  );
};

export default ResoursesSectionInHome;
