import React from 'react';
const CustomCardForServices = ({ title, imageUrl, iconImage,subTitle }) => {
    return (
      <div className="flex justify-center items-center py-10 mx-2">
        <div
          className="relative flex-none 
         w-full rounded-lg shadow-lg py-4 bg-white px-4"
        >
               <h2 className="text-2xl mb-3 text-big-text-color
                font-semibold cursor-pointer text-center">
              {title}
            </h2>
          {/* Card Image */}
          <div className="relative mb-5 h-40 overflow-hidden rounded-md bg-gray-200">
            <img
              alt={title}
              src={imageUrl}
              className="h-full w-full object-cover object-center"
            />
          </div>
  
        
  
          {/* Card Content */}
          <div className="flex flex-col items-center justify-center mt-5">
            <p className="xxxxxs:text-xs xxxxs:text-xs xxxs:text-xs xxs:text-xs xs:text-xs
            sm:text-sm lg:text-sm xl:text-sm 1xl:text-sm 11xl:text-sm 2xl:text-sm 22xl:text:sm
            text-gray-700 text-center mt-2">
              {subTitle}
            </p>
          </div>
        </div>
      </div>
    );
  };
  
export default CustomCardForServices;
