import React from 'react';

const CustomParagraph =({title})=>{
    return(
        <p className="mt-2 text-xs text-gray-600  overflow-hidden mb-1" 
   style={{
       display: '-webkit-box',
       WebkitBoxOrient: 'vertical',
       WebkitLineClamp: 2,
       overflow: 'hidden',
       maxHeight: '3em', 
       lineHeight: '1.5em' 
   }}>
    {title}
</p>

    )
}

export default CustomParagraph;