import React from 'react';
import EventImage from '../../../asset/images/mapevent.png';
const MapEvent =()=>{

    return(
        <div className=' xxxs:w-full xxs:w-full xs:w-full xxxxs:w-full xxxxxs:w-full
    sm:w-[600px] lg:w-[600px] xl:w-[700px] 1xl:w-[800px] 11xl:w-[760px] 2xl:w-[900px] 3xl:w-[1100px] 
         bg-white rounded-custom mt-5 p-4'>
         <h3 className='xxxs:text-xl xxs:text-xl xs:text-xl sm:text-3xl lg:text-3xl xl:text-3xl 1xl:text-3xl 11xl:text-3xl 2xl:text-3xl 3xl:text-3xl font-bold pt-2 text-big-text-color '>
         Event Map
        </h3>
        <img
        src={EventImage}
        alt={EventImage}
        className='w-full px-4 h-[200px] mt-4'
        />
        <div className='flex justify-center items-center'>
            <h2 className='text-sm text-big-text-color mr-1'>
            Do you want going event? 
            </h2>
            <h2 className='text-sm text-big-text-color font-bold'>
            Open location in map
            </h2>
            </div>
        
             </div>
    )
}

export default MapEvent;