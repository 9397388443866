import React from 'react';
import CustomCardForNewsLetter from './customCradNewLetter';
import imgNewsOne from '../../../asset/images/onenew.jpg';
import imgNewsTwo from '../../../asset/images/newtwo.jpg';
import imgNewsThree from '../../../asset/images/threenews.jpg';
import imgNewsFour from '../../../asset/images/fournews.jpg';
import imgNewsLetterOne from '../../../asset/images/newsletterone.jpg';
import imgNewsLetterTwo from '../../../asset/images/newslettertwo.jpg';
import imgNewsLetterThree from '../../../asset/images/newsletterthree.jpg';
import imgNewsLetterFour from '../../../asset/images/newsletterfour.avif';
import imgNewsLetterFive from '../../../asset/images/newsletterfive.webp';
import CustomButton from '../sharedcomponent/custombutton';
import CustomBigTitle from '../homeuser/custombigtitle';
import CustomCardForNewsLetterShadow from './customCardShadow';
const CustomSectionIntro =()=>{
    return (
  <div className='  xxxxxs:px-4 xxxxs:px-4 xxxs:px-4 xxs:px-4
    sm:px-20 md:px-20 mid:px-20 lg:px-20 xl:px-20 1xl:px-20 11xl:px-20 2xl:px-20 3xl:px-20 mt-10 mb-10' >
       <CustomButton name='BIS Newsletters'/>
       <CustomBigTitle title='To keep you up-to-date about industry news and provide timely information about trends, BIS publishes a number of newsletters.'/>

  <div className="sm:flex flex-wrap w-full mb-10">
  <div className="w-full md:w-1/4 ">
    <CustomCardForNewsLetter
      imageUrl={imgNewsOne}
      title="BIS Insider"
         navigateTo='/FormNewsLetter'
      subTitle="From trending insights to exclusive perks, here’s what’s happening this month at [Brand Name]."
    />
  </div>
  <div className="w-full md:w-1/4 ">
    <CustomCardForNewsLetter
      imageUrl={imgNewsTwo}
      title="The Buzz"
         navigateTo='/FormNewsLetter'
      subTitle="Your monthly dose of updates, insights, and exclusive offers is here. Let’s dive in! The Buzz"
    />
  </div>
  <div className="w-full md:w-1/4 ">
    <CustomCardForNewsLetter
      imageUrl={imgNewsThree}
      title="Talent Brief"
         navigateTo='/FormNewsLetter'
      subTitle="From trending insights to exclusive perks, here’s what’s happening this month at [Brand Name]."
    />
  </div>
  <div className="w-full md:w-1/4 ">
    <CustomCardForNewsLetter
      imageUrl={imgNewsFour}
      title="Talented Development"
         navigateTo='/FormNewsLetter'
      subTitle="From trending insights to exclusive perks, here’s what’s happening this month at [Brand Name]."
    />
  </div>
</div>
<CustomBigTitle title='Sign up to receive newsletters from BIS topic areas catered to your interest.'/>

<div className="sm:flex flex-wrap w-full  mt-10">
  <div className="w-full md:w-1/4 ">
    <CustomCardForNewsLetterShadow
      imageUrl={imgNewsLetterFive}
      title="Instrucal Design"
         navigateTo='/FormNewsLetter'
      subTitle="Explore the latest on e-learning and using technology to support learning and performance."
    />
  </div>
  <div className="w-full md:w-1/4 ">
    <CustomCardForNewsLetterShadow
      imageUrl={imgNewsLetterOne}
      title="LeaderShip And Management"
         navigateTo='/FormNewsLetter'
      subTitle="Strengthen leadership capabilities within the organization and identify trends in enhancing future talent."
    />
  </div>
  <div className="w-full md:w-1/4 ">
    <CustomCardForNewsLetterShadow
      imageUrl={imgNewsLetterThree}
      title="Learning Technologies"
         navigateTo='/FormNewsLetter'
      subTitle="The use of technology to improve or facilitate learning and talent development enhancing future talent. "
    />
  </div>
  <div className="w-full md:w-1/4 ">
    <CustomCardForNewsLetterShadow
      imageUrl={imgNewsLetterTwo}
      title="Sales Enablment"
         navigateTo='/FormNewsLetter'
      subTitle="Explore the latest content about sales team management, sales tools, positioning, and more future talent."
    />
  </div>
</div>

<div className="sm:flex flex-wrap w-full ">
  <div className="w-full md:w-1/4 ">
    <CustomCardForNewsLetterShadow
      imageUrl={imgNewsOne}
      title="Organization Development"
      subTitle="Using strategic human resource planning to improve business value and make it possible "
      navigateTo='/FormNewsLetter'
    />
  </div>
  <div className="w-full md:w-1/4 ">
    <CustomCardForNewsLetterShadow
      imageUrl={imgNewsThree}
      title="Traning Delivery"
      subTitle="Delivering informal and formal learning solutions. Browse the latest on facilitation and leading engaging learning"
         navigateTo='/FormNewsLetter'
    />
  </div>
  <div className="w-full md:w-1/4 ">
    <CustomCardForNewsLetterShadow
      imageUrl={imgNewsLetterFour}
      title="My Career"
      subTitle="Filled with cutting-edge career development content, tools, templates, best practices, events, and more."
         navigateTo='/FormNewsLetter'
    />
  </div>
  <div className="w-full md:w-1/4 ">
    <CustomCardForNewsLetterShadow
      imageUrl={imgNewsLetterThree}
      title="AI Insights"
         navigateTo='/FormNewsLetter'
      subTitle="A monthly newsletter full of the latest insights and trends around AI in talent development around AI in talent development."
    />
  </div>
</div>

  </div>
    )
}
export default CustomSectionIntro;