import React from 'react';

const CustomContactInfo = ({ icon: Icon, title, children }) => {
    return (
        <div className='flex flex-col items-center
        sm:mx-5 lg:mx-5 xl:mx-5 1xl:mx-5  11xl:mx-5 2xl:mx-5
         22xl:mx-5 3xl:mx-5 my-10 p-4 xxxs:w-full xxs:w-full xs:w-full
         bg-white shadow-lg rounded-lg px-2'>
            <div className='flex items-center justify-center w-16 h-16 text-yellow-700 mb-5 border rounded-custom border-primary-color'>
                <Icon className='
                xxxxxs:w-4 xxxxxs:w-4  xxxxs:w-4 xxxxs:w-4 xxxs:w-4 xxxs:w-4 xxs:w-4 xxs:w-4 xs:w-4 xs:w-4
                sm:w-8 sm:h-8 lg:w-8 lg:h-8 xl:w-8 xl:h-8 1xl:w-8 1xl:h-8 11xl:w-8 11xl:h-8 2xl:w-8 2xl:h-8 22xl:w-8 22xl:h-8 3xl:w-8 3xl:h-8' />
            </div>
            <h3 className='xxxs:text-xl xxs:text-xl xs:text-xl 
             sm:text-xl lg:text-sm xl:text-xl 1xl:text-xl 11xl:text-xl 2xl:text-xl 3xl:text-xl
              font-bold mb-3 text-big-text-color'>{title}</h3>
            <p className='xxxs:text-xs xxs:text-xs xs:text-xs 
             sm:text-sm lg:text-xs xl:text-sm 1xl:text-sm 11xl:text-sm
              2xl:text-sm 3xl:text-sm text-big-text-color'>
                {children}
            </p>
        </div>
    );
};

export default CustomContactInfo;

