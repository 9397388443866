import React from 'react';

const CustomBigTitle =({title})=>{

    return(
        <h3 className="lg:text-3xl xl:text-3xl  2xl:text-4xl  uppercase
        font-semibold tracking-tight text-big-text-color items-center text-center   ">
        {title}
      </h3>
    ) 
}
export default CustomBigTitle;