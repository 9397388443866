import React from 'react';
import  {ShoppingCartIcon} from '@heroicons/react/24/outline'
import img from '../../../asset/images/iconwhiteright.png';
const Table = () => {
  return (
    <div className=" my-5 w-full px-5">
      <div className=" rounded-lg ">
       <div className=' border-t  border-r border-l  border-big-text-color'>
       <table className=" w-full">
       <thead className="w-full">
  <tr className="border-b border-big-text-color w-full">
    <th className="py-2 text-center text-xs font-bold text-gray-700 
    uppercase tracking-wider border-r border-big-text-color">
      Member
    </th>
    <th className="py-2 text-center text-xs font-bold text-gray-700 uppercase tracking-wider border-big-text-color">
      Non-Member
    </th>
  </tr>
</thead>



          <tbody className="bg-white">
          <tr className="border-b border-gray-300">
  <td className="px-4 py-2 text-xs text-center border-r border-big-text-color text-big-text-color">
    1500 Egp
  </td>
  <td className="px-4 py-2 text-xs text-center border-r text-big-text-color">
    2000 Egp
  </td>
</tr>
        </tbody>
      </table>
       </div>
      <div className='flex'>
            <button className="bg-big-text-color w-full text-white px-4 py-2 flex items-center justify-center text-xs rounded-bl-custom">
  Enroll Course
  <img src={img} alt={img} className="w-2 h-2 ml-2" />
</button>

<button className="bg-primary-color flex items-center justify-center w-full text-white px-4 py-2 text-xs rounded-br-custom">
  Add To Cart
  <ShoppingCartIcon className="w-4 h-4 ml-2" />
</button>

              </div>
    </div>
    </div>
  );
};

export default Table;
