import React from 'react';

const CustomCardForNewsLetterShadow = ({ title, imageUrl, subTitle, navigateTo }) => {

  const handleCardClick = () => {
    window.open(navigateTo, '_blank'); // Open the link in a new tab
  };

  return (
    <div 
      className="flex justify-center items-center py-10 mx-2 cursor-pointer" 
      onClick={handleCardClick}
    >
      <div
        className="relative flex-none mx-2
        w-full h-[400px]
        rounded-custom shadow-lg py-4 bg-white px-6"
      >
        {/* Card Image */}
        <div className="relative mb-5 h-40 overflow-hidden rounded-md bg-gray-200">
          <img
            alt={title}
            src={imageUrl}
            className="h-full w-full object-cover object-center"
          />
        </div>

        <h2 className="text-2xl mb-3 text-big-text-color font-semibold cursor-pointer text-center">
          {title}
        </h2>

        {/* Card Content */}
        <div className="flex flex-col items-center justify-center mt-5">
          <p className="text-sm text-gray-600 text-center mt-2">
            {subTitle}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CustomCardForNewsLetterShadow;
