import React from 'react';

const CustomButtonDiv = ({ name }) => {
  return (
    <div className='flex justify-center'>
      <div className='mb-5 items-center'>
        <div
          className="rounded-custom bg-custom-button 
          xxxs:px-2 xxxs:py-1 xxs:px-2 xxs:py-1 xs:px-2 xs:py-1
          sm:px-6 sm:py-1 lg:px-6 lg:py-1 xl:px-6 xl:py-1 1xl:px-6 1xl:py-1 11xl:px-6 
          11xl:py-1 2xl:px-6 2xl:py-1 3xl:px-6 3xl:py-1 text-sm font-semibold
           text-custom-blue shadow-sm 
           
            font-semibold   font-sans " 
        >
          {name}
        </div>
      </div>
    </div>
  );
};

export default CustomButtonDiv;