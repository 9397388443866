import React, { useState } from 'react';
import DualRangeSlider from './rangeslider';
import CustomSideTitle from './customsidetitle';
import CustomCheckBox from './checkbox';
import CustomCheckBoxStar from './checkboxreview';
import LocationFilter from './locationfilter';
import DataFilter from './datafilteration';

const CustomSortedForMobileCourses = () => {
  const [isFilterOpen, setIsFilterOpen] = useState(false); // Full-screen filter open state

  // Function to toggle full-screen filter view
  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  return (
    <div>
      {/* Button to open the full-screen filter */}
      <h3 className='text-sm font-bold  text-big-text-color mb-1'>
    Refine By
    </h3>
      <button
  className="py-2 px-6 w-[180px] placeholder-big-text-color 
  font-lg
  text-big-text-color bg-transparent rounded-custom border
   border-big-text-color focus:outline-none focus:ring-2 focus:ring-blue-600"
  onClick={toggleFilter}
>
  Filters
</button>


      {/* Full-screen filter panel */}
      {isFilterOpen && (
        <div className="fixed inset-0 bg-white z-50 flex flex-col">
          {/* Close Button */}
          <button
            className="absolute top-4 right-4 text-2xl text-gray-500"
            onClick={toggleFilter}
          >
            &times;
          </button>

          {/* Filter content */}
          <div className="flex-1 overflow-y-auto p-6">
            {/* Filters sections */}
            <div className="space-y-6">
              {/* SORT BY ACCESS */}
              <div>
                <CustomSideTitle title="SORT By ACCESS" />
                <CustomCheckBox title="Member Benefits" />
              </div>

              {/* SORT BY START TIME */}
              {/* DualRangeSlider - Static Positioned */}
      <div className="sticky  bg-white  p-6 border-b">
        <CustomSideTitle title="SORT By START TIME" />
        <DualRangeSlider />
      </div>

              {/* Role */}
              <div>
                <CustomSideTitle title="Role" />
                <div className="flex flex-col space-y-2">
                  <CustomCheckBox title="Student" />
                  <CustomCheckBox title="Employee" />
                  <CustomCheckBox title="Manager" />
                </div>
              </div>

              {/* Topic */}
              <div>
                <CustomSideTitle title="TOPIC" />
                <div className="flex flex-col space-y-2">
                  <CustomCheckBox title="Technology Application" />
                  <CustomCheckBox title="Collaboration" />
                  <CustomCheckBox title="Data and Analytics" />
                </div>
              </div>

              {/* Levels */}
              <div>
                <CustomSideTitle title="Levels" />
                <div className="flex flex-col space-y-2">
                  <CustomCheckBox title="All Levels" />
                  <CustomCheckBox title="Beginner" />
                  <CustomCheckBox title="Intermediate" />
                  <CustomCheckBox title="Expert" />
                </div>
              </div>

              {/* Locations */}
              <div>
                <CustomSideTitle title="LOCATIONS" />
                <LocationFilter />
              </div>

              {/* Date */}
              <div>
                <CustomSideTitle title="DATE" />
                <DataFilter />
              </div>

              {/* Sort By Language */}
              <div>
                <CustomSideTitle title="Sort By Language" />
                <div className="flex flex-col space-y-2">
                  <CustomCheckBox title="Arabic" />
                  <CustomCheckBox title="English" />
                </div>
              </div>

              {/* Rating */}
              <div>
                <CustomSideTitle title="Rating" />
                <div className="flex flex-col space-y-2">
                  <CustomCheckBoxStar
                    starColors={[
                      'text-custom-border',
                      'text-custom-border',
                      'text-custom-border',
                      'text-custom-border',
                      'text-custom-border',
                    ]}
                    title="5 STAR"
                  />
                  <CustomCheckBoxStar
                    starColors={[
                      'text-custom-border',
                      'text-custom-border',
                      'text-custom-border',
                      'text-custom-border',
                      'text-gray-300',
                    ]}
                    title="4 STAR"
                  />
                  <CustomCheckBoxStar
                    starColors={[
                      'text-custom-border',
                      'text-custom-border',
                      'text-custom-border',
                      'text-gray-300',
                      'text-gray-300',
                    ]}
                    title="3 STAR"
                  />
                  <CustomCheckBoxStar
                    starColors={[
                      'text-custom-border',
                      'text-gray-300',
                      'text-gray-300',
                      'text-gray-300',
                      'text-gray-300',
                    ]}
                    title="2 STAR"
                  />
                  <CustomCheckBoxStar
                    starColors={[
                      'text-gray-300',
                      'text-gray-300',
                      'text-gray-300',
                      'text-gray-300',
                      'text-gray-300',
                    ]}
                    title="0 STAR"
                  />
                </div>
              </div>
         
          
            
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomSortedForMobileCourses;
