import React from 'react';

const CustomCardFaq = ({ title, imageUrl, subTitle, navigateTo }) => {
 
    const handleCardClick = () => {
      window.open(navigateTo, '_blank');
    };
  
    return (
      <div
        className="flex justify-center
        rounded-custom
        items-center py-5 cursor-pointer border border-big-text-color "
        onClick={handleCardClick}
      >
        <div
          className="relative flex-none 
          w-full
          rounded-custom py-4 bg-white px-6"
        >
       
  
          <h2 className="text-2xl mb-3 text-big-text-color font-semibold cursor-pointer text-center">
            {title}
          </h2>
  
          {/* Card Content */}
          <div className="flex flex-col items-center justify-center mt-5">
            <p
              style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 3,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: '0.75rem',
                color: '#4B5563',
                textAlign: 'center',
                marginTop: '0.5rem',
              }}
            >
              {subTitle}
            </p>
          </div>
        </div>
      </div>
    );
  };
  

export default CustomCardFaq;