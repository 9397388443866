import React ,{useState} from "react";
import ScrollToggle from "../homeuser/ScrollToggle";

const CustomThingsToDo =({title})=>{
   // State to track which ScrollToggle is open
   const [openIndex, setOpenIndex] = useState(0); // Open the first ScrollToggle by default

   const toggleScroll = (index) => {
     setOpenIndex(openIndex === index ? -1 : index); // Close if already open, otherwise open the clicked one
   };
 
      return (
        <div className='  xxxs:w-full xxs:w-full xs:w-full xxxxs:w-full xxxxxs:w-full
        sm:w-[600px] lg:w-[600px] xl:w-[700px] 1xl:w-[800px] 11xl:w-[760px] 2xl:w-[900px] 3xl:w-[1100px] 
          bg-white rounded-custom mt-5 p-4 relative'>
        <h3 className='xxxs:text-xl xxs:text-xl xs:text-xl sm:text-3xl lg:text-3xl xl:text-3xl 1xl:text-3xl 11xl:text-3xl 2xl:text-3xl 3xl:text-3xl font-bold text-big-text-color'>
        {title}
        </h3>
          <div className=" p-2">
          <ScrollToggle title='How To Subscribe & Our Fees' subtitle='Interior Design Course'
             isOpen={openIndex === 0}
             onClick={() => toggleScroll(0)}
          />
          <ScrollToggle title='Admision Eligibility' subtitle='Interior Design Course'
             isOpen={openIndex === 1}
             onClick={() => toggleScroll(1)}
          />
          <ScrollToggle title='How To Subscribe & Our Fees' subtitle='Interior Design Course'
             isOpen={openIndex === 2}
             onClick={() => toggleScroll(2)}
          />
          <ScrollToggle  title='How To Subscribe & Our Fees' subtitle='Interior Design Course'
             isOpen={openIndex === 2}
             onClick={() => toggleScroll(2)}
          />
        </div>
      </div>
      )
}

export default CustomThingsToDo;