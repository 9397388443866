import React from 'react';

const ListInBell = () => { 
    const items = [
        {
            title: 'Default Course Title',
            instructor: 'Default Instructor',
        },
        {
            title: 'Another Default Course Title',
            instructor: 'Another Instructor',
        },
    ];

    return (
        <div className='space-y-4'>
            {items.map((item, index) => (
                <div key={index} className='flex justify-between'>
                        <div className='ml-4'>
                            <h3 className='xxxxxs:text-sm xxxxs:text-sm xxxs:text-sm xxs:text-sm xs:text-sm sm:text-lg lg:text-lg
                    xl:text-lg 1xl:text-lg 11xl:text-lg 2xl:text-lg 22xl:text-lg  3xl:text-lg text-big-text-color font-bold'>
                                {item.title}
                            </h3>
                            <h3 className='xxxxxs:text-xs xxxxs:text-xs xxxs:text-xs xxs:text-xs xs:text-xs sm:text-sm lg:text-sm
                            xl:text-sm 1xl:text-sm 11xl:text-sm 2xl:text-sm 22xl:text-sm 3xl:text-sm text-big-text-color'>
                                {item.instructor}
                            </h3>
                        </div>
                
    
                </div>
            ))}
        </div>
    );
};

export default ListInBell;
