import React, { useState } from 'react';
import { Range, getTrackBackground } from 'react-range';

const DualRangeSlider = () => {
  const [values, setValues] = useState([25, 75]);
  const MIN = 0;
  const MAX = 100;

  return (
    <div className="flex flex-col items-center w-full p-2">
      <Range
        step={1}
        min={MIN}
        max={MAX}
        values={values}
        onChange={(values) => setValues(values)}
        renderTrack={({ props, children }) => (
          <div
            {...props}
            className="h-1 w-full rounded"
            style={{
              background: getTrackBackground({
                values,
                colors: ['#000', '#000', '#000'], // Black for track, blue for selected range
                min: MIN,
                max: MAX,
              }),
            }}
          >
            {children}
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            className={`h-6 w-6 rounded-custom bg-custom-border 
               focus:outline-none focus:ring-1 focus:ring-custom-border ${
              isDragged ? 'ring-1 ring-custom-border' : ''
            }`}
          />
        )}
      />
      <div className="flex justify-between w-full mt-2">
        <span className="text-neutral-700 dark:text-neutral-200">{values[0]}</span>
        <span className="text-neutral-700 dark:text-neutral-200">{values[1]}</span>
      </div>
    </div>
  );
};

export default DualRangeSlider;
