import React from "react";
import CustomTextRefund from "./customtext"; // A custom component for reusable text blocks
import image from "../../../asset/images/Rectangle 42337.png"; // Use an image if necessary

const StackRefund = () => {
  return (
    <div className="flex flex-col min-h-screen">
      {/* Gradient Background */}
      <div className="bg-custom-gradient-div h-80 text-white flex items-center justify-center">
        <h1 className="text-4xl font-bold">Refund Policy</h1>
      </div>

      {/* Main Content */}
      <div
        className="relative top-[-230px] mx-4 sm:mx-28 mt-10 mb-10 bg-white flex flex-col shadow-lg items-center justify-center rounded-lg"
      >
        {/* Section Title */}
        <h3 className="text-2xl font-bold mb-4 mt-10 text-big-text-color">
          Refund Policy
        </h3>

        {/* Sections */}
        <div className="w-full space-y-10 px-4 sm:px-20 mb-10">
          {/* Refund Eligibility */}
          <Section
            title="Refund Eligibility"
            content="You can request a refund within [X] days of purchase. To be eligible for a refund, the product must be unused and in the original packaging."
          />

          {/* Non-Refundable Items */}
          <Section
            title="Non-Refundable Items"
            content="Certain items, such as gift cards, downloadable software, or customized products, are not eligible for refunds. Please check the product description before purchasing."
          />

          {/* Refund Process */}
          <Section
            title="Refund Process"
            content="To request a refund, please contact us via email or the contact form on our website. Once your refund is approved, we will process it within [X] business days."
          />

          {/* Damaged or Defective Products */}
          <Section
            title="Damaged or Defective Products"
            content="If you receive a damaged or defective product, please notify us immediately at [Your Contact Email] and we will arrange for a return, replacement, or refund."
          />

          {/* Refund Method */}
          <Section
            title="Refund Method"
            content="Refunds will be issued to the original payment method. Please note that depending on your bank or payment provider, it may take additional business days for the funds to reflect in your account."
          />

          {/* Contact Information */}
          <Section
            title="Contact Information"
            content="For questions regarding refunds or to request a refund, please contact us at [Your Contact Email] or [Your Contact Phone Number]."
          />
        </div>
      </div>
    </div>
  );
};

/**
 * Section Component
 * A reusable component for displaying section titles and content.
 */
const Section = ({ title, content }) => (
  <div>
    <h3 className="text-xl sm:text-2xl font-bold text-start mb-3 text-big-text-color">
      {title}
    </h3>
    <CustomTextRefund title={content} />
  </div>
);

export default StackRefund;
