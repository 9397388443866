import React, { useState, useEffect } from 'react';
import logo from '../../../asset/images/BIS_png_1.png';
import FlyoutMenu from './flayoutmenus';
import {
    MagnifyingGlassIcon,
    BellIcon,
    UserIcon,
    ShoppingCartIcon,
    XMarkIcon,
    Bars4Icon
} from '@heroicons/react/24/outline';
import { Button, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import ShadowButton from './buttonshadow';
import CustomTitleAndImage from './customtitleandimage';
import image from '../../../asset/images/Ellipse 82.png';
import { Link, useLocation } from 'react-router-dom';

import ListInCart from '../../users/homeuser/listincart';
import ListInBell from '../../users/homeuser/listofbell';
import CustomProfileAndImageOnly from './customprofilandtitle';

const CustomNavBarWithFlayout = () => {
    const location = useLocation();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 840);
    const iconSize = isMobile ? 'w-5 h-5' : 'w-6 h-6';
    
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isBellDrawerOpen, setIsBellDrawerOpen] = useState(false);
    const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);
    const toggleBellDrawer = () => setIsBellDrawerOpen(!isBellDrawerOpen);

    // Update isMobile state on window resize
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className=' flex flex-row justify-between items-center py-4 
        xxxs:px-4 xxs:px-4 xs:px-4 xxxxs:px-4 xxxxxs:px-4 sm:px-12 mid:px-12 md:px-12
           lg:pl-20 xl:pl-20 1xl:pl-20 11xl:pl-20 2xl:pl-20 3xl:pl-20
             lg:pr-16 xl:pr-16 1xl:pr-16 11xl:pr-16
            2xl:pr-16 22xl:pr-16 3xl:pr-16
          sm:ml-6 sm:mr-4 lg:ml-6 lg:mr-4 xl:ml-6 xl:mr-4 1xl:ml-6 1xl:mr-4 11xl:ml-6 11xl:mr-4 
           2xl:ml-6 2xl:mr-4 22xl:ml-6 22xl:mr-4 3xl:ml-6 3xl:mr-4'>
            <div className='flex justify-between items-center w-full md:w-auto'>
            <Link to="/">
                <img
                    src={logo}
                    alt="Logo"
                    className='w-32 h-10 md:w-40 md:h-12'
                />
                </Link>

{/*          
                <button className='md:hidden flex items-center p-2'>
                    <MagnifyingGlassIcon className='w-6 h-6 text-gray-700' />
                </button> */}
            </div>
            <div className='hidden md:flex items-center'>
                <FlyoutMenu />
            </div>
            <div className='flex items-center  space-x-4'>
                {location.pathname === '/searchview' ? (
                    <Link to='/'>
                        <XMarkIcon className='w-6 h-6 m-1 cursor-pointer text-big-text-color' />
                    </Link>
                ) : (
                    <Link to='/searchview'>
                        <MagnifyingGlassIcon className='w-6 h-6 m-1 cursor-pointer text-big-text-color' />
                    </Link>
                )}  
                 <Button onClick={toggleBellDrawer}>
                            <BellIcon className={`${iconSize} text-big-text-color`} />
                        </Button>
                        <button onClick={toggleDrawer}>
                            <ShoppingCartIcon className={`${iconSize} text-big-text-color`} />
                        </button>
                <Menu as="div" className="relative ">
                            <MenuButton className="flex items-center text-gray-700">
                                <UserIcon aria-hidden="true" className={`${iconSize} text-big-text-color`} />
                                <div className='head-user-container'>
                                    <span className={`ml-2 hidden md:inline text-big-text-color
                                        text-sm
                                        `}>Sign In</span>
                                </div>
                            </MenuButton>
                            <MenuItems className="absolute right-0 z-50 mt-2 w-48 origin-top-right rounded-md bg-white p-2 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none">
                                <MenuItem>
                                    <CustomProfileAndImageOnly title='Tarek Tantawy' subtitle='Edit Profile' imageSrc={image} />
                                </MenuItem>
                                <MenuItem>
                                    <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                        Bookmarks
                                    </a>
                                </MenuItem>
                                <MenuItem>
                                    <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                        Account
                                    </a>
                                </MenuItem>
                                <MenuItem>
                                    <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                        Orders
                                    </a>
                                </MenuItem>
                                <MenuItem>
                                    <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                        Downloads
                                    </a>
                                </MenuItem>
                                <MenuItem>
                                    <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                        Help
                                    </a>
                                </MenuItem>
                                <hr />
                                <MenuItem>
                                    <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                        Sign out
                                    </a>
                                </MenuItem>
                            </MenuItems>
                        </Menu>

                {isMobile && (
                    <Button className="ml-2">
                        <Bars4Icon className={`${iconSize} text-big-text-color`} />
                    </Button>
                )}

                <div className='hidden md:block ml-2'>
                    <ShadowButton className='ml-2 z-0' children='Become a Member' />
                </div>
                   {/* Shopping Cart Drawer */}
            {isDrawerOpen && (
                <div className="fixed inset-0 flex z-50 h-screen justify-end">
                    <div className="absolute inset-0 bg-black opacity-50" onClick={toggleDrawer}></div>
                    <div className={`bg-white shadow-lg transition-transform transform ${isDrawerOpen ? 'translate-x-0' : 'translate-x-full'} w-[500px] h-full`}>
                        <div className="h-full p-8">
                            <div className='flex justify-between mt-10'>
                                <h2 className="text-3xl text-big-text-color font-bold">Shopping Cart</h2>
                                <Link to='/' onClick={toggleDrawer}>
                                    <XMarkIcon className='w-6 h-6 m-1 cursor-pointer text-red' />
                                </Link>
                            </div>
                            <hr className="px-8 mt-5" />
                            <div className='h-[200px] mt-10'>
                                <ListInCart />
                            </div>
                            <div className='flex justify-between mt-5'>
                                <h3 className='text-lg text-big-text-color font-bold'>SubTotal:</h3>
                                <h3 className='text-lg text-big-text-color'>124$</h3>
                            </div>
                            <div className="grid place-items-center mt-4">
                                <button
                                    onClick={toggleDrawer}
                                    className="bg-primary-color text-white px-6 py-2 rounded text-lg w-full"
                                >
                                    Checkout
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
              {/* Bell Drawer */}
              {isBellDrawerOpen && (
                <div className="fixed inset-0 flex z-50 h-screen justify-end">
                    <div className="absolute inset-0 bg-black opacity-50" onClick={toggleBellDrawer}></div>
                    <div className={`bg-white shadow-lg transition-transform transform ${isBellDrawerOpen ? 'translate-x-0' : 'translate-x-full'} w-[500px] h-full`}>
                        <div className="h-full p-8">
                            <div className='flex justify-between mt-10'>
                                <h2 className="text-3xl text-big-text-color font-bold">Notifications</h2>
                                <Link to='/' onClick={toggleBellDrawer}>
                                    <XMarkIcon className='w-6 h-6 m-1 cursor-pointer text-red' />
                                </Link>
                            </div>
                            <hr className="px-8 mt-5" />
                            <div className='h-[200px] mt-10'>
                                <ListInBell />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            </div>
        </div>
    );
};

export default CustomNavBarWithFlayout;

