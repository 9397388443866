import React, { useState } from 'react';
import CustomSideTitle from '../coursesuser/customsidetitle';
import CustomCheckBox from '../coursesuser/checkbox';
import IndustryFilter from '../eventsuser/industryfilter';

const CustomSortedForMobileMembership = () => {
  const [isFilterOpen, setIsFilterOpen] = useState(false); // Full-screen filter open state

  // Function to toggle full-screen filter view
  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  return (
    <div>
      {/* Button to open the full-screen filter */}
      <button
        className="py-2 px-6 w-[180px] placeholder-big-text-color 
        font-lg text-big-text-color bg-transparent rounded-custom border
        border-big-text-color focus:outline-none focus:ring-2 focus:ring-blue-600"
        onClick={toggleFilter}
      >
        Sort Options
      </button>

      {/* Full-screen filter panel */}
      {isFilterOpen && (
        <div className="fixed inset-0 bg-white z-50 flex flex-col">
          {/* Close Button */}
          <button
            className="absolute top-4 right-4 text-2xl text-gray-500"
            onClick={toggleFilter}
          >
            &times;
          </button>

          {/* Filter content */}
          <div className="flex-1 overflow-y-auto p-6">
            {/* Filters sections */}
            <div className="space-y-6">
              {/* INDUSTRY Section */}
              <div>
                <CustomSideTitle title="INDUSTRY" />
                <IndustryFilter />
              </div>

              {/* ROLE Section */}
              <div>
                <CustomSideTitle title="Role" />
                <div className="flex flex-col space-y-2">
                  <CustomCheckBox title="Student" />
                  <CustomCheckBox title="Employee" />
                  <CustomCheckBox title="Manager" />
                </div>
              </div>

              {/* DURATION Section */}
              <div>
                <CustomSideTitle title="DURATION" />
                <div className="flex flex-col space-y-2">
                  <CustomCheckBox title="1 Month" />
                  <CustomCheckBox title="5 Months" />
                  <CustomCheckBox title="1 Year" />
                  <CustomCheckBox title="2 Years" />
                  <CustomCheckBox title="5 Years" />
                  <CustomCheckBox title="10 Years" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomSortedForMobileMembership;
