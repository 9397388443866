import React, { useEffect, useState, useRef } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import image from '../../../asset/images/goodevent.png';
import CustomIconAndText from '../sharedcomponent/customiconandtext';
import { MapPinIcon, ClockIcon } from "@heroicons/react/24/outline";
import CustomTitleAndImageOnly from '../sharedcomponent/imageandtitleonly';
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/24/solid';
import arrowRightImage from '../../../asset/images/arrowrightwhite.png';
import imageteam from '../../../asset/images/d1af7d099058eb24786ab692d7f35bbc.png';
import imageBlogsOne from '../../../asset/images/blogone.png';
import imageBlogsTwo from '../../../asset/images/blogtwo.png';
import { useNavigate } from 'react-router-dom';
import BookmarkButton from '../sharedcomponent/customBookMark';
import CustomParagraph from '../sharedcomponent/customParagraph';
import CustomIconAndTextDate from '../sharedcomponent/customTitleAndIconDates';
import CustomButton from '../sharedcomponent/custombutton';
const products = [
  {
    id: 1,
    name: 'How To Upgrade ',
    href: '#',
    contant: 'Leverage exclusive ATD templates and tools to develop the most powerful,Leverage exclusive ATD templates and tools  bottom-line focused learning',
    imageSrc: image,
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 2,
    name: 'Grow your business',
    href: '#',
    contant: 'Utilize innovative learning frameworks and resources to create effective,Leverage exclusive ATD templates and tools Leverage exclusive ATD templates and tools results-driven training.',
    imageSrc: imageBlogsOne,
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 3,
    name: 'Advices In Yours',
    href: '#',
    contant: 'Leverage exclusive ATD templates and tools to develop the most powerful,Leverage exclusive ATD templates and tools Leverage exclusive ATD templates and tools bottom-line focused learning',
    imageSrc: imageBlogsTwo,
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 4,
    name: 'How To Upgrade ',
    href: '#',
    contant: 'Leverage exclusive ATD templates and tools to develop the most powerful, Leverage exclusive ATD templates and tools Leverage exclusive ATD templates and tools bottom-line focused learning',
    imageSrc: image,
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 5,
    name: 'Grow your business',
    href: '#',
    contant: 'Utilize innovative learning frameworks and resources Leverage exclusive ATD templates and tools Leverage exclusive ATD templates and tools to create effective, results-driven training.',
    imageSrc: imageBlogsOne,
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 6,
    name: 'Advices In Yours',
    href: '#',
    contant: 'Leverage exclusive ATD templates and tools to develop the most powerful,Leverage exclusive ATD templates and tools Leverage exclusive ATD templates and tools bottom-line focused learning',
    imageSrc: imageBlogsTwo,
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  // Additional products...
];
const CustomBlogs = () => {
    const scrollRef = useRef(null);
    const navigate = useNavigate();
  
    const scrollOneItem = (direction) => {
      if (scrollRef.current) {
        const itemWidth = scrollRef.current.children[0].offsetWidth;
        const scrollAmount = direction === 'left' ? -itemWidth : itemWidth;
        scrollRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
      }
    };
  
    const handleClick = () => {
      navigate('/blogsInside');
    };
  
    return (
      <div className="bg-transparent justify-center items-center px-2 pb-5">
        <div className="justify-center items-center xxxs:px-10 xxs:px-10
         xs:px-10 xxxxs:px-10 xxxxxs:px-10 lg:px-24 mx-auto">
          <div className="relative flex items-center">
            {/* Left Arrow */}
            <button
              onClick={() => scrollOneItem('left')}
              className="absolute left-[-50px] z-10 p-2 text-white bg-primary-color 
              rounded-full shadow-md"
              style={{ top: '50%', transform: 'translateY(-50%)' }}
            >
              <ArrowLeftIcon className="w-6 h-6" />
            </button>
  
            {/* Scrollable Container */}
            <div
              ref={scrollRef}
              className="flex overflow-x-auto sm:p-4 w-full scrollbar-hidden space-x-3"
              style={{ scrollSnapType: 'x mandatory' }}
            >
              {products.map((product) => (
                <div
                  key={product.id}
                  className="relative flex-none w-full sm:w-1/2 md:w-1/3 
                  lg:w-1/3 xl:w-1/3 min-w-[100px] lg:min-w-[25%] rounded-lg shadow-sm bg-white p-4"
                  style={{ scrollSnapAlign: 'start' }}
                >
                  <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md
                   bg-gray-200 lg:h-32 mb-3">
                    {/* Reduced the height of the image container */}
                    <img
                      alt={product.imageAlt}
                      src={product.imageSrc}
                      className="h-full w-full object-cover object-center"
                    />
                  </div>
                  <div className="flex justify-between mt-3">
                    <h2
                      className="lg:text-lg xl:text-lg text-big-text-color font-semibold cursor-pointer"
                      onClick={handleClick}
                    >
                      {product.name}
                    </h2>
                  </div>
  
                  <p
                    className="mt-2 xxxs:text-xs xxs:text-xs xs:text-xs xxxxs:text-xs xxxxxs:text-xs
                     lg:text-lg text-gray-600 font-sans overflow-hidden mb-1"
                    style={{
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 6,
                      overflow: 'hidden',
                      maxHeight: '6em', // Reduce the height of the description
                      lineHeight: '1.5em',
                    }}
                  >
                    {product.contant}
                  </p>
                  <div className="flex justify-between mt-4 items-center">
                    <p className="text-lg font-medium text-primary-color"></p>
                    <button
                      onClick={handleClick}
                      className="text-sm text-white border rounded-custom
                       bg-primary-color px-3 py-2 flex items-center"
                    >
                      See Details
                    </button>
                  </div>
                </div>
              ))}
            </div>
  
            {/* Right Arrow */}
            <button
              onClick={() => scrollOneItem('right')}
              className="absolute right-[-50px] z-10 p-2 text-white bg-primary-color rounded-full shadow-md"
              style={{ top: '50%', transform: 'translateY(-50%)' }}
            >
              <ArrowRightIcon className="w-6 h-6" />
            </button>
          </div>
        </div>
      </div>
    );
  };
  
  export default CustomBlogs;
  