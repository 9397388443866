import React, { useState } from 'react';
import CustomSideTitle from '../coursesuser/customsidetitle';
import CustomCheckBox from '../coursesuser/checkbox';
import CustomCheckBoxStar from '../coursesuser/checkboxreview';
import CategoryFilter from '../shopuser/categoryfilter';
import DataFilter from '../coursesuser/datafilteration';
import IndustryFilter from '../eventsuser/industryfilter';
import LocationFilter from '../coursesuser/locationfilter';

const CustomSortedForMobileShops = () => {
  const [isFilterOpen, setIsFilterOpen] = useState(false); // Full-screen filter toggle state

  // Toggle the full-screen filter visibility
  const toggleFilter = () => {
    setIsFilterOpen((prev) => !prev);
  };

  return (
    <div>
      {/* Button to open the full-screen filter */}
      <button
        className="py-2 px-6 w-[180px] placeholder-big-text-color 
        font-lg text-big-text-color bg-transparent rounded-custom border
        border-big-text-color focus:outline-none focus:ring-2 focus:ring-blue-600"
        onClick={toggleFilter}
      >
        Filters
      </button>

      {/* Full-screen filter panel */}
      {isFilterOpen && (
        <div className="fixed inset-0 bg-white z-50 flex flex-col">
          {/* Close Button */}
          <button
            className="absolute top-4 right-4 text-2xl text-gray-500"
            onClick={toggleFilter}
          >
            &times;
          </button>

          {/* Filter content */}
          <div className="flex-1 overflow-y-auto p-6 space-y-6">
            {/* ACCESS Section */}
            <div>
              <CustomSideTitle title="ACCESS" />
              <CustomCheckBox title="Member Benefits" />
            </div>

            {/* CATEGORIES Section */}
            <div>
              <CustomSideTitle title="CATEGORIES" />
              <CategoryFilter />
            </div>

            {/* INDUSTRY Section */}
            <div>
              <CustomSideTitle title="INDUSTRY" />
              <IndustryFilter />
            </div>

            {/* LOCATIONS Section */}
            <div>
              <CustomSideTitle title="LOCATIONS" />
              <LocationFilter />
            </div>

            {/* DATE Section */}
            <div>
              <CustomSideTitle title="DATE" />
              <DataFilter />
            </div>

            {/* Language Section */}
            <div>
              <CustomSideTitle title="Sort By Language" />
              <div className="flex flex-col space-y-2">
                <CustomCheckBox title="Arabic" />
                <CustomCheckBox title="English" />
              </div>
            </div>

            {/* Rating Section */}
            <div>
              <CustomSideTitle title="RATING" />
              <div className="flex flex-col space-y-2">
                <CustomCheckBoxStar
                  starColors={['text-custom-border', 'text-custom-border', 'text-custom-border', 'text-custom-border', 'text-custom-border']}
                  title="5 STAR"
                />
                <CustomCheckBoxStar
                  starColors={['text-custom-border', 'text-custom-border', 'text-custom-border', 'text-custom-border', 'text-gray-300']}
                  title="4 STAR"
                />
                <CustomCheckBoxStar
                  starColors={['text-custom-border', 'text-custom-border', 'text-custom-border', 'text-gray-300', 'text-gray-300']}
                  title="3 STAR"
                />
                <CustomCheckBoxStar
                  starColors={['text-custom-border', 'text-custom-border', 'text-gray-300', 'text-gray-300', 'text-gray-300']}
                  title="2 STAR"
                />
                <CustomCheckBoxStar
                  starColors={['text-custom-border', 'text-gray-300', 'text-gray-300', 'text-gray-300', 'text-gray-300']}
                  title="1 STAR"
                />
                <CustomCheckBoxStar
                  starColors={['text-gray-300', 'text-gray-300', 'text-gray-300', 'text-gray-300', 'text-gray-300']}
                  title="0 STAR"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomSortedForMobileShops;
