import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StarReview from "../../admin/reviews";
import image from '../../../asset/images/sideimage.png';
import CustomIconAndText from '../../users/sharedcomponent/customiconandtext';
import { BookmarkIcon ,ClockIcon ,PaperClipIcon } from "@heroicons/react/24/outline";
import CustomTitleAndImage from '../../users/sharedcomponent/customtitleandimage';
import imageblog from '../../../asset/images/bloggrid.png';
import img from '../../../asset/images/Icon.png';
import { useNavigate } from 'react-router-dom';
import CustomParagraph from "../sharedcomponent/customParagraph";
import BookmarkButton from '../sharedcomponent/customBookMark';
import CustomNameAndImageOnly from '../resoursesuser/nameAndImage';
import CustomIconAndTextDate from "../sharedcomponent/customTitleAndIconDates";

const products = [
  {
    id: 1,
    name: 'Grow your business ',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 2,
    name: 'Grow your business ',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 3,
    name: 'Grow your business ',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 1,
    name: 'Grow your business ',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 2,
    name: 'Basic Tee',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 3,
    name: 'Basic Tee',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
];

export default function AllBlogsGrid() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/blogInside');
  };
    return (
     
      <div className="flex w-full mx-auto xxxs:px-0 xxs:px-0 xs:px-0 
      sm:px-6 lg:px-8 xl:px-2 1xl:px-2  11xl:px-2  2xl:px-2 22xl:px-2 3xl:px-2">
       <div className="w-full mx-auto">
        <div className="grid grid-cols-1 gap-x-8 gap-y-12 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3
         11xl:grid-cols-3 1xl:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-4">
          {products.map((product) => (
         <div key={product.id} 
         className="group relative
          xxxs:w-full xxs:w-full xs:w-full sm:w-full 
               lg:w-[250px] xl:w-[250px] 11xl:w-[260px] 1xl:w-[270px] 2xl:w-[330px]
             22xl:w-[330px]
              3xl:w-[300px] 
               lg:mx-0 xl:mx-0 2xl:mx-10 xxxs:2xl:mx-0 xxs:mx-0 xs:mx-0 sm:mx-0 
             bg-white rounded-lg shadow-lg overflow-hidden transition-transform duration-300 transform hover:scale-105">
             <div className=" w-fullflex justify-center items-center p-4">
  <img
    alt={imageblog}
    src={imageblog}
    className="h-40 w-full object-cover object-center rounded-custom"
  />
</div>

  
                <div className="pt-1.5 min-w-0 flex-1 sm:pt-0 p-4">
                  <div className='justify-between flex'>
                    <StarReview />
                    <BookmarkButton/>
                  </div>
  
                  <h3 className="text-lg font-semibold text-big-text-color">
                    <a href={product.href}>{product.name}</a>
                  </h3>
                  <div className="lg:flex xl:flex 1xl:flex 11xl:flex 2xl:flex 3xl:flex space-x-3 mt-3 mb-3">
                    <CustomIconAndTextDate icon={<ClockIcon className="sm:h-3 sm:w-3 lg:h-3 lg:w-3
                   xl:h-3 xl:w-3 1xl:h-3 1xl:w-3 11xl:h-3 11xl:w-3 2xl:h-5 2xl:w-5 22xl:h-5 22xl:w-5 
                    3xl:h-3 3xl:w-3  text-gray-500" />} title='16 Nov, 2024' />
                    <CustomIconAndText icon={<PaperClipIcon className="sm:h-3 sm:w-3 lg:h-3 lg:w-3
                   xl:h-3 xl:w-3 1xl:h-3 1xl:w-3 11xl:h-3 11xl:w-3 2xl:h-5 2xl:w-5 22xl:h-5 22xl:w-5 
                    3xl:h-3 3xl:w-3 text-gray-500" />} title='15 Downloads' />
                  </div>
                  <CustomParagraph
                  title='Engage in conversations about renewable energy, zero waste initiatives.'
                  />
                  <div className='flex justify-between'>
                  <CustomNameAndImageOnly 
                      imageSrc={image}
                      title="Tarek"
                    />
                     <button 
onClick={handleClick}
className="mt-1 xxxs:text-xs xxs:text-xs xs:text-xs lg:text-sm xl:text-sm 1xl:text-sm 11xl:text-sm 2xl:text-sm 3xl:text-sm  text-gray-500 border rounded-custom border-gray-400 bg-transparent px-2 py-1 flex items-center justify-center">
Read More
<img
    src={img}
    alt="Course Icon"
    className="ml-2 justify-center items-center"
  />
</button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }