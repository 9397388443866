import React, { useRef, useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StarReview from "../../admin/reviews";
import { ClockIcon, PaperClipIcon, ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import CustomTitleAndImageOnly from '../../users/sharedcomponent/imageandtitleonly';
import CustomParagraph from '../sharedcomponent/customParagraph';
import BookmarkButton from '../sharedcomponent/customBookMark';
import CustomIconAndTextDate from "../sharedcomponent/customTitleAndIconDates";
import CustomResourcesButton from "./customResourcesButton";
import { useNavigate } from "react-router-dom";
import imageResourses from '../../../asset/images/resourseimage.png';
import imageResourseOne from '../../../asset/images/imagesgrow.jpg';
import imageResourseTwo from '../../../asset/images/70242659-requirements-concept-on-tablet-pc-screen.jpg';
import image from '../../../asset/images/sideimage.png';
import CustomIconAndText from '../sharedcomponent/customiconandtext';
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/24/solid';

const products = [
  { id: 1, name: 'Grow your business ', imageResourse: imageResourses, href: '#' },
  { id: 2, name: 'Expand your reach ', imageResourse: imageResourseOne, href: '#' },
  { id: 3, name: 'Optimize your strategy ', imageResourse: imageResourseTwo, href: '#' },
  { id: 4, name: 'Scale your team ', imageResourse: imageResourses, href: '#' },
  { id: 5, name: 'Maximize your efficiency ', imageResourse: imageResourseOne, href: '#' },
  { id: 6, name: 'Innovate continuously ', imageResourse: imageResourseTwo, href: '#' },
];

export default function AllResourcesListInHome() {
  const scrollRef = useRef(null);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/resourcesInside');
  };

  const scrollOneItem = (direction) => {
    if (scrollRef.current) {
      const itemWidth = scrollRef.current.children[0].offsetWidth;
      const scrollAmount = direction === 'left' ? -itemWidth : itemWidth;
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  return (
    <div className="bg-transparent justify-center items-center px-2 pb-5">
      <div className="justify-center items-center xxxs:px-10 xxs:px-10 xs:px-10 xxxxs:px-10 xxxxxs:px-10 lg:px-24">
        <div className="relative flex items-center">
          {/* Left Arrow */}
          <button
            onClick={() => scrollOneItem('left')}
            className="absolute left-[-50px] z-10 p-2 text-white bg-primary-color rounded-full
             shadow-md focus:outline-none"
            style={{ top: '50%', transform: 'translateY(-50%)' }}
          >
            <ArrowLeftIcon className="w-6 h-6" />
          </button>

          {/* Scrollable Container */}
          <div
            ref={scrollRef}
            className="flex overflow-x-auto sm:p-4 w-full scrollbar-hidden space-x-3"
            style={{ scrollSnapType: 'x mandatory' }}
          >
            {products.map((product) => (
              <div
                key={product.id}
                className="relative flex-none w-full sm:w-1/2 md:w-1/3
                 lg:w-1/3 xl:1/4 min-w-[100px] lg:min-w-[25%] rounded-lg shadow-sm bg-white "
                style={{ scrollSnapAlign: 'start' }}
              >
                <div className="flex justify-center items-center p-4">
                  <img
                    alt={product.name}
                    src={product.imageResourse}
                    className="h-40 w-full object-cover object-center rounded-custom"
                  />
                </div>

                <div className="pt-1.5 min-w-0 flex-1 sm:pt-0 p-4">
                  <div className="justify-between flex">
                    <StarReview />
                    <BookmarkButton />
                  </div>

                  <h3
                    className="lg:text-lg xl:text-lg 2xl:text-2xl font-semibold text-big-text-color"
                    onClick={handleClick}
                    style={{ cursor: 'pointer' }}
                  >
                    {product.name}
                  </h3>

                  <div className="flex space-x-4 mt-3 mb-3">
                    <CustomIconAndTextDate
                      icon={<ClockIcon className="h-4 w-4 text-gray-500" />}
                      title="16 Nov, 2024"
                    />
                    <CustomIconAndText
                      icon={<PaperClipIcon className="h-4 w-4 text-gray-500" />}
                      title="15 Downloads"
                    />
                  </div>

                  <CustomParagraph
                    title="Engage in conversations about renewable energy, zero waste initiatives."
                  />
                  <div className="flex justify-between  mt-2">
                    <CustomTitleAndImageOnly
                      onTitleClick={handleClick}
                      imageSrc={image}
                      title="tantawy"
                    />
                    <CustomResourcesButton
                      onClick={handleClick}
                      children="Read More"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Right Arrow */}
          <button
            onClick={() => scrollOneItem('right')}
            className="absolute right-[-50px] z-10 p-2 text-white bg-primary-color rounded-full shadow-md
              focus:outline-none"
            style={{ top: '50%', transform: 'translateY(-50%)' }}
          >
            <ArrowRightIcon className="w-6 h-6" />
          </button>
        </div>
      </div>
    </div>
  );
}