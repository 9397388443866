import React from "react";
import CollapseFaq from './collapseFaq';
import CustomBigTitle from "../homeuser/custombigtitle";
const SectionQuestionFaq = () => {
  return (
    <div className="mt-10 mb-10 xxxxxs:px-4 xxxxs:px-4 xxxs:px-4 xxs:px-4 xs:px-4 
        sm:px-20 lg:px-20 xl:px-20 1xl:px-20 11xl:px-20 2xl:px-20 3xl:px-20">
    <div className="mb-10 mt-10">
    <CustomBigTitle title='Promoted Articals'/>

    </div>
      {/* First Row */}
    <div className=" sm:flex flex-wrap justify-between">
    <div className="w-full sm:w-1/2 px-2" >
    
          <CollapseFaq
            title="What is sentiment analysis?"
            subTitle="Each question is styled with a clear distinction, making it easy to navigate through the FAQ section."
          />
     
          <CollapseFaq
            title=" How accurate is the sentiment analysis?"
            subTitle="Each question is styled with a clear distinction, making it easy to navigate through the FAQ section."
          />
          <CollapseFaq
            title=" How do you ensure data privacy?"
            subTitle="Each question is styled with a clear distinction, making it easy to navigate through the FAQ section."
          />
          <CollapseFaq
            title=" How do you ensure data privacy?"
            subTitle="Each question is styled with a clear distinction, making it easy to navigate through the FAQ section."
          />
          <CollapseFaq
            title="How do you ensure data privacy?"
            subTitle="Each question is styled with a clear distinction, making it easy to navigate through the FAQ section."
          />
        
          <CollapseFaq
            title="How do you ensure data privacy?"
            subTitle="Each question is styled with a clear distinction, making it easy to navigate through the FAQ section."
          />
      </div>

      {/* Second Row */}
      <div className="w-full sm:w-1/2 px-2" >
          <CollapseFaq
            title="How do I get started with your service?"
            subTitle="Each question is styled with a clear distinction, making it easy to navigate through the FAQ section."
          />
     
          <CollapseFaq
            title="Is there a trial version of the service?"
            subTitle="Each question is styled with a clear distinction, making it easy to navigate through the FAQ section."
          />
            
          <CollapseFaq
            title="Can I cancel my subscription anytime?"
            subTitle="Each question is styled with a clear distinction, making it easy to navigate through the FAQ section."
          />
          <CollapseFaq
            title="Can I cancel my subscription anytime?"
            subTitle="Each question is styled with a clear distinction, making it easy to navigate through the FAQ section."
          />
    
          <CollapseFaq
            title="Can I cancel my subscription anytime?"
            subTitle="Each question is styled with a clear distinction, making it easy to navigate through the FAQ section."
          />
        
          <CollapseFaq
            title="Can I cancel my subscription anytime?"
            subTitle="Each question is styled with a clear distinction, making it easy to navigate through the FAQ section."
          />
   
      </div>

    </div>

       
    </div>
  );
};

export default SectionQuestionFaq;
