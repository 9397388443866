import React from 'react';
import CustomCardFaq from './customCardFaq';
import BorderButton from '../sharedcomponent/borderbutton';
import { useNavigate } from 'react-router-dom';

const SectionFaq = () => {
    const navigate = useNavigate();

    const handleClick = () => {
      navigate('/FormNewsLetter'); // Use navigate instead of navigator
    };
    return (
        <div className='mt-10 mb-10
        xxxxxs:mx-2 xxxxs:mx-2 xxxs:mx-2 xxs:mx-2 xs:mx-2
        xxxxxs:px-4 xxxxs:px-4 xxxs:px-4 xxs:px-4 xs:px-4 
        sm:px-20 lg:px-20 xl:px-20 1xls:px-20 11xl:px-20 2xl:px-20 3xl:px-20
         justify-center items-center'>
         <div className='sm:flex justify-center items-center mb-10 mt-10 '>
  <BorderButton onClick={handleClick}>Submit a Request </BorderButton>
</div>

            <div className='grid grid-cols-1 sm:grid-cols-3 gap-4 '>
                <CustomCardFaq
                    title='What is the Business Index Sentiments?'
                    subTitle='Business Index Sentiments is a tool designed to track and analyze the overall sentiment of business.'
                />
                <CustomCardFaq
                    title='How does the sentiment analysis work?'
                    subTitle='Our sentiment analysis uses advanced algorithms and machine learning models to analyze large amounts.'
                />
                <CustomCardFaq
                    title='How do I interpret the sentiment scores?'
                    subTitle='The sentiment scores are calculated based on the sentiment of the articles and reports. A positive sentiment.'
                />
            </div>
            <div className='grid grid-cols-1 sm:grid-cols-3 gap-4 mt-4'>
                <CustomCardFaq
                    title='How do I contact customer support?'
                    subTitle='If you need assistance or have any questions, our customer support team is here to help. You can contact.'
                />
                <CustomCardFaq
                    title='Can I cancel my subscription at any time?'
                    subTitle='Adjust content based on your specific business or service offering. For example, if you are offering.'
                />
                <CustomCardFaq
                    title='How do I interpret the sentiment scores?'
                    subTitle='The sentiment scores are calculated based on the sentiment of the articles and reports. A positive sentiment.'
                />
            </div>
            <div className='grid grid-cols-1 sm:grid-cols-3 gap-4 mt-4'>
                <CustomCardFaq
                    title='What is the Business Index Sentiments?'
                    subTitle='Business Index Sentiments is a tool designed to track and analyze the overall sentiment of business.'
                />
                <CustomCardFaq
                    title='How does the sentiment analysis work?'
                    subTitle='Our sentiment analysis uses advanced algorithms and machine learning models to analyze large amounts.'
                />
                <CustomCardFaq
                    title='How do I interpret the sentiment scores?'
                    subTitle='The sentiment scores are calculated based on the sentiment of the articles and reports. A positive sentiment.'
                />
            </div>
        </div>
    );
}

export default SectionFaq;
