import React from "react";
import YouTubeEmbed from './customvideo';
import { Button } from "@headlessui/react";
import CourseDetails from './coursesidebardetials';
import linkedinImage from '../../../asset/images/lin.png';
import FacebookImage from '../../../asset/images/fac.png';
import instgramImage from '../../../asset/images/int.png';
import Table from './tableinsidebar';
import image from '../../../asset/images/sideimage.png'
import { BookmarkIcon } from "@heroicons/react/24/outline";
import CustomTitleAndSubTitle from '../../../components/users/coursesuser/customtitleandsubtitle';
import BookmarkButton from "../sharedcomponent/customBookMark";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faTiktok, faLinkedinIn, faWhatsapp ,faYoutube,faXTwitter } from '@fortawesome/free-brands-svg-icons';

const SideBarCourseInside = () => {
    return (
        <div className="border border-custom-border
         lg:w-[250px] xl:w-[320px] 1xl:w-[400px] 11xl:w-[320px] 2xl:w-[400px] 22xl:w-[400px] 3xl:w-[400px] 
          rounded-custom bg-white 
          flex flex-col hidden-mobile">
            <YouTubeEmbed/>
            <div className="flex justify-between px-5 py-2">
                <div className="flex items-center">
                    <img
                        src={image}
                        alt={image}
                        className="w-6 h-6 mr-2"
                    />
                    <h2 className="text-sm text-big-text-color">
                        Dr. Tarek Eltantawy
                    </h2>
                </div>
                <BookmarkButton/>
            </div>
            <Table/>
            <CustomTitleAndSubTitle title='Language' subTitle='English'/>
            <CustomTitleAndSubTitle title='Level' subTitle='7'/>
            <CustomTitleAndSubTitle title='Role' subTitle='Entry-Level'/>
            <div className="flex flex-col items-center justify-center mb-4">
                <div className="flex mb-2 items-center justify-center">
                    <h2 className="text-big-text-color text-lg font-bold mr-2">
                        Share
                    </h2>

      <a href="https://facebook.com/" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faFacebookF} className='text-big-text-color mr-2 ' />
      </a>
      <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faInstagram} className='text-big-text-color mr-2' />
      </a>
      <a href="https://www.youtube.com/channel/YourChannelID" target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon icon={faXTwitter}  className="text-big-text-color  " />

</a>
                </div>
            </div>
            <Button className="w-full rounded-bl-lg rounded-br-lg bg-big-text-color text-white py-2 hover:bg-big-text-color">
            Call    01029999210 
            </Button>
        </div>
    );
};

export default SideBarCourseInside;
