import React from 'react';
import CustomContant from '../../../components/users/coursesuser/customcontant';
const CourseContantSectionInsideCourseDetials = ()=>{
   return(
     <div className='
     xxxs:w-full xxs:w-full xs:w-full xxxxs:w-full xxxxxs:w-full
       sm:w-[600px] lg:w-[600px] xl:w-[700px] 1xl:w-[800px] 11xl:w-[760px] 2xl:w-[900px] 3xl:w-[1100px] 
              bg-white mt-5 p-4'>
            <h3 className='
            xxxs:text-xl  xxs:text-xl  xs:text-xl
            sm:text-3xl lg:text-3xl xl:text-3xl 1xl:text-3xl 11xl:text-3xl 2xl:text-3xl 3xl:text-3xl font-bold  text-big-text-color'>
            Course Content
     </h3>
   
  <CustomContant title='Introduction' subTitle='Intro'/>
  <CustomContant title='UnderStand React' subTitle='Basic'/>
  <CustomContant title='Host And JSX' subTitle='Hook'/>
  
  <CustomContant title='React Prop' subTitle='Basic'/>


     </div>
    )
}

export default CourseContantSectionInsideCourseDetials;