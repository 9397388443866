import React, { useState } from "react";
import img from '../../../asset/images/imagesres.jpg';
import CustomCheckBox from "../coursesuser/checkbox";

const CheckoutForm = () => {
  // Defining the state for the checkboxes
  const [checkedState, setCheckedState] = useState({
    Card: false,
    Paypal: false,
    "Save card details": false,
  });

  // Defining the function to handle checkbox changes
  const handleCheckboxChange = (name) => {
    setCheckedState(prevState => ({
      ...prevState,
      [name]: !prevState[name]
    }));
  };

  // Example of an item object, replace this with actual data as needed
  const item = {
    title: "Course Title",
    instructor: "Instructor Name",
  };

  return (
    <div className="xxxs:mx-4 xxs:mx-4 xs:mx-4 sm:mx-20 lg:mx-20 xl:mx-24
     1xl:mx-24 11xl:mx-24 2xl:mx-24 3xl:mx-24 block shadow-lg rounded-lg bg-white p-8 sm:flex ">
      <div className="sm:w-1/2 px-2 mb-5">
        <h3 className="text-xl font-bold mb-5 text-big-text-color">
          Payment
        </h3>
        <hr className="w-full" />
        <h3 className="text-lg font-bold mb-5 text-big-text-color">
          Pay With:
        </h3>
        <div className="flex space-x-2">
          <CustomCheckBox
            isChecked={checkedState["Card"]}
            onChange={() => handleCheckboxChange("Card")}
            title="Card"
          />
          <CustomCheckBox
            isChecked={checkedState["Paypal"]}
            onChange={() => handleCheckboxChange("Paypal")}
            title="Paypal"
          />
        </div>
        {/* Card input field */}
        <div className="mb-4">
          <label htmlFor="cardNumber" className="block text-sm font-semibold text-gray-700">
            Credit Card Number
          </label>
          <input
            id="cardNumber"
            type="text"
            placeholder="1234 5678 9012 3456"
            className="mt-2 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>

        {/* Expiry Date input */}
        <div className="mb-4 flex space-x-4">
          <div className="w-1/2">
            <label htmlFor="expiry" className="block text-sm font-semibold text-gray-700">
              Expiry Date
            </label>
            <input
              id="expiry"
              type="text"
              placeholder="MM/YY"
              className="mt-2 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>

          {/* CVV input */}
          <div className="w-1/2">
            <label htmlFor="cvv" className="block text-sm font-semibold text-gray-700">
              CVV
            </label>
            <input
              id="cvv"
              type="text"
              placeholder="123"
              className="mt-2 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>
        </div>
        <CustomCheckBox
          isChecked={checkedState["Save card details"]}
          onChange={() => handleCheckboxChange("Save card details")}
          title="Save card details"
        />
        {/* Submit Button */}
        <div className="flex justify-center mb-5 mt-2">
          <button
            type="submit"
            className="block w-full rounded-lg bg-primary-color px-8 py-3 text-base font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            Sign up
          </button>
        </div>
        <p className="text-sm">
          Your personal data will be used to process your order, support your experience throughout this website,
          and for other purposes described in our privacy policy.
        </p>
      </div>

      <div className="sm:w-1/2 px-2 ">

        <h3 className="text-xl font-bold mb-5 sm:mt-5 text-big-text-color">
          Order Summary
        </h3>
        <hr className="w-full" />
        <div className=" flex  ">
          <img
            src={img}
            alt="checkoutImg"
            className="w-12 h-12 mr-2 mt-2 mb-2"
          />
          <div>
            <h3 className="xxxxxs:text-sm xxxxs:text-sm xxxs:text-sm xxs:text-sm xs:text-sm sm:text-lg lg:text-lg xl:text-lg 1xl:text-lg 11xl:text-lg 2xl:text-lg 22xl:text-lg 3xl:text-lg text-big-text-color font-bold">
              {item.title}
            </h3>
            <h3 className="xxxxxs:text-xs xxxxs:text-xs xxxs:text-xs xxs:text-xs xs:text-xs sm:text-sm lg:text-sm xl:text-sm 1xl:text-sm 11xl:text-sm 2xl:text-sm 22xl:text-sm 3xl:text-sm text-big-text-color">
              {item.instructor}
            </h3>
          </div>
        </div>
        <hr className="w-full" />
        <div className="flex justify-between">
          <h3 className="text-lg text-big-text-color">
            Subtotal
          </h3>
          <h3 className="text-lg text-gray-500">
            $30
          </h3>
        </div>
        <div className="flex justify-between">
          <h3 className="text-lg text-big-text-color">
            Shipping
          </h3>
          <h3 className="text-lg text-gray-500">
            $30
          </h3>
        </div>
        <hr className="w-full" />
        <div className="flex justify-between">
          <h3 className="text-lg text-big-text-color">
            Total
          </h3>
          <h3 className="text-xl text-gray-500">
            $3000
          </h3>
        </div>
      </div>
    </div>
  );
};

export default CheckoutForm;
