import React from 'react';
import image from '../../../asset/images/coursebar.png'; // Default image

const ListInCart = () => { 
    const items = [
        {
            image: '', // You can set a default image here if needed
            title: 'Default Course Title',
            instructor: 'Default Instructor',
            price: 0, // Default price
        },
        {
            image: '',
            title: 'Another Default Course Title',
            instructor: 'Another Instructor',
            price: 10, // Another default price
        },
    ];

    return (
        <div className='space-y-4'>
            {items.map((item, index) => (
                <div key={index} className='flex justify-between'>
                    <div className='flex'>
                        <img
                            src={item.image || image} // Use specific image or fallback
                            alt={item.title}
                            className='w-24 h-24 rounded-custom'
                        />
                        <div className='ml-4'>
                            <h3 className='xxxxxs:text-sm xxxxs:text-sm xxxs:text-sm xxs:text-sm xs:text-sm sm:text-lg lg:text-lg
                    xl:text-lg 1xl:text-lg 11xl:text-lg 2xl:text-lg 22xl:text-lg  3xl:text-lg text-big-text-color font-bold'>
                                {item.title}
                            </h3>
                            <h3 className='xxxxxs:text-xs xxxxs:text-xs xxxs:text-xs xxs:text-xs xs:text-xs sm:text-sm lg:text-sm
                            xl:text-sm 1xl:text-sm 11xl:text-sm 2xl:text-sm 22xl:text-sm 3xl:text-sm
                            text-big-text-color'>
                                {item.instructor}
                            </h3>
                        </div>
                    </div>
                    <h3 className='xxxxxs:text-sm xxxxs:text-sm xxxs:text-sm xxs:text-sm xs:text-sm sm:text-lg lg:text-lg
                    xl:text-lg 1xl:text-lg 11xl:text-lg 2xl:text-lg 22xl:text-lg  3xl:text-lg
                    text-big-text-color font-bold'>
                        {item.price}$
                    </h3>
                </div>
            ))}
        </div>
    );
};

export default ListInCart;
