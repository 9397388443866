import React from 'react';
import CustomTitleAndImage from '../sharedcomponent/customtitleandimage';
import imageProfessionalism from '../../../asset/images/11233665 1.png';
import imageQuality from '../../../asset/images/Group 1000016302.png';
import ShadowButton from '../sharedcomponent/buttonshadow';
import backgroundLogoTarget from '../../../asset/images/about.png';
import one from '../../../asset/images/aboutthere.png';
import two from '../../../asset/images/pixelcut-export.png';
import three from '../../../asset/images/aboutone.png';
import CustomButton from '../sharedcomponent/custombutton';
import  arrowRightImage from '../../../asset/images/arrowrightwhite.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward ,faUserTie } from '@fortawesome/free-solid-svg-icons';

const AboutUsSectionHome = () => {
  return (
    <div className="relative overflow-hidden bg-transparent 
    mt-20 lg:px-12 xl:px-12 1xl:px-12 11xl:px-12 2xl:px-12 3xl:px-12  xxs:px-2 xxxs:px-2 xs:px-2">
      <div className="pb-10 sm:pb-10 lg:pb-10">
        <div className="relative mx-auto max-w-8xl px-10 sm:px-16 lg:px-14">
          <div className="flex flex-col lg:flex-row lg:space-x-8">
          <div className=' flex justify-center mobily-only sm:hidden'>
          <CustomButton name='About Us'/>
          </div>
            <div className="flex-shrink-0 
            xxxs:w-full xxs:w-full xs:w-full xxxxs:w-full xxxxxs:w-full
            sm:w-1/2 lg:w-1/2 xl:w-1/2 1xl:w-1/2 11xl:w-1/2 2xl:w-1/2 3xl:w-1/2   relative">
              <div className="relative grid grid-cols-1 gap-y-10 lg:grid-cols-2 lg:gap-x-6">
                {/* First Row */}
                <div className="relative xxxs:h-40 xxs:h-40 xs:h-40 xxxxx:h-60 xxxxxs:h-60
                sm:h-80 lg:h-80 xl:h-80 1xl:h-80 11xl:h-80 2xl:h-80 3xl:h-80
                 xxxs:w-40 xxs:w-40 xs:w-40 xxxxs:w-[300px] xxxxxs:w-[200px]
                 sm:w-[400px] lg:w-full xl:w-full 1xl:w-full 11xl:w-full 2xl:w-full 3xl:w-full overflow-hidden rounded-lg z-10">
                  <img
                    alt={one}
                    src={one}
                    className="h-full w-full object-cover object-center"
                  />
                </div>

                {/* Second Row */}
                <div className="relative grid  xxxs:top-[-180px]  xxs:top-[-180px]  xs:top-[-180px] xxxxs:top-[-280px] xxxxxs:top-[-280px] 
                sm:top-[-380px]  lg:top-0 xl:top-0 1xl:top-0 11xl:top-0 2xl:top-0 3xl:top-0
                xxxs:right-[-180px]  xxs:right-[-180px]   xs:right-[-180px] 
                 xxxxs:right-[-320px] xxxxxs:right-[-220px]
                 sm:right-[-420px] lg:right-0 xl:right-0 1xl:right-0 11xl:right-0 2xl:right-0 3xl:right-0
                grid-cols-2 gap-x-6 lg:grid-cols-2 lg:gap-x-6">
                  <div className="relative xxxs:h-30 xxxs:w-30 xxs:h-30 xxs:w-30 xs:h-30 xs:w-30
                  xxxxs:h-[210px] xxxxxs:w-[130px]
                  sm:h-60 sm:w-60 lg:h-60 lg:w-60 xl:h-60 xl:w-60 1xl:h-60 1xl:w-60 11xl:h-60 11xl:w-60 2xl:h-60 2xl:w-60  3xl:h-60 3xl:w-60 overflow-hidden rounded-lg z-10">
                    <img
                      alt={two}
                      src={two}
                      className="h-full w-full object-cover object-center"
                    />
                  </div>
                </div>
                <div className="relative   top-[-140px] sm:top-[-260px] xxxxs:top-[-260px]  xxxxxs:top-[-260px]  xxxs:h-20 xxs:h-20 xs:h-20 xxxxs:h-[120px] xxxxxs:h-[120px]
                 sm:h-[200px] lg:h-80 xl:h-80 1xl:h-80 11xl:h-80 2xl:h-120 3xl:h-120 w-full overflow-hidden rounded-lg z-0">
                    <img
                      alt={backgroundLogoTarget}
                      src={backgroundLogoTarget}
                      className="h-full w-full object-cover object-center"
                    />
                  </div>
             {/* Absolute positioned image */}
<div className="absolute  
xxxs:bottom-20 xxs:bottom-20 xs:bottom-20 xxxxs:bottom-60 xxxxxs:bottom-40
sm:bottom-60 lg:bottom-20 xl:bottom-20 1xl:bottom-20 11xl:bottom-20 2xl:bottom-20 3xl:bottom-20 
xxxs:right-10 xxs:right-10 xs:right-10 xxxxs:right-10 xxxxxs:right-10
sm:right-[-180px]
lg:right-60 xl:right-40 2xl:right-80 3xl:right-100 4xl:right-160
 xxxs:h-60 xxxs:w-40 xxs:h-60 xxs:w-40 xs:h-60 xs:w-40 xxxxs:h-60 xxxxs:w-40  xxxxxs:h-60 xxxxxs:w-40 
  sm:h-80 sm:w-[380px] xxxxs:w-[350px] xxxxxs:w-[220px] 
lg:h-80 lg:w-[250px] xl:h-80 xl:w-[250px] 1xl:h-80 1xl:w-[250px] 11xl:h-80 11xl:w-[250px] 2XL:h-80 2XL:w-[250px] 3XL:h-80 3XL:w-[250px]
 overflow-hidden rounded-lg z-10">
  <img
    alt={three}
    src={three}
    className="h-full w-full object-cover object-center"
  />
</div>

              </div>
            </div>

            {/* Text on the right */}
            <div className="lg:w-1/2  xl:w-1/2 1xl:w-1/2 11xl:w-1/2 2xl:w-1/2 3xl:w-1/2
             items-start justify-start 
             
             lg:ml-20 xl:ml-20 1xl:ml-20 11xl:ml-20 2xl:ml-20 3xl:ml-20">
          <div className=' flex justify-start hidden-mobile'>
          <CustomButton name='About Us'/>
          </div>
              <h1 className="
            xxxs:text-lg xxs:text-lg xs:text-lg xxxxs:text-lg xxxxxs:text-lg 
        sm:text-xl mid:text-xl md:text-xl
        lg:text-2xl xl:text-2xl 1xl:text-2xl 11xl:text-2xl 2xl:text-2xl 22xl:text-2xl
         3xl:text-2xl font-semibold tracking-tight text-big-text-color  mb-5">
              KNOW ABOUT BUSINESS INDEX SENTIMENTS LEARNING PLATFORM
              </h1>
              <p className="mt-8  sm:text-sm lg:text-sm xl:text-sm 1xl:text-sm
           11xl:text-lg 2xl:text-lg 22xl:text-lg 3xl:text-lg text-gray-400 mb-4 ">
                Masterpieces of achievement are not by the number of wins But it is in the process that preceded this achievement of planning, attitudes, and success Based on that, we would like to offer a message of thanks, appreciation, and gratitude to a person who gave his effort and taught us a lot and a lot Special thanks and appreciation to the business development consultant Dr. Tarek Tantawi
              </p>
            <div className='mb-6 mt-6 flex'>
            <div className="flex flex-col justify-center items-center text-center">
  {/* <FontAwesomeIcon 
    icon={faAward} 
    className="text-primary-color h-12 w-12 mb-2"  // Add margin to space it from the title
  />     */}
  <img
  src={imageProfessionalism}
  className='w-14 h-14'
  />
  <CustomTitleAndImage
    title="Professionalism"
    subtitle="Professionalism reflects our commitment to 
    providing high-quality services and adhering to professional work standards and ethics."
  />
</div>

         <div className='flex flex-col justify-center items-center text-center'>
          {/* <FontAwesomeIcon icon={faUserTie} className='text-primary-color h-12 w-12 mb-2' /> */}
          <img
          src={imageQuality}
          className='w-10 h-12'
          />
              <CustomTitleAndImage
                title="Quality"
                subtitle="Achieving quality in every aspect of our
                 services is the key to our success. We strive to achieve the highest levels of quality."
              />
          </div>
            </div>
       
              <div className="mt-12">
                <ShadowButton
                image={arrowRightImage}
                children='ABOUT US'/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsSectionHome;
