import React from 'react';
import CustomCardForServices from './customCardForServices';
import imagecourseone from '../../../asset/images/events.png';
import imageResourses from '../../../asset/images/resourseimage.png';
import CustomButton from '../sharedcomponent/custombutton';
import CustomBigTitle from './custombigtitle';

const CustomServicesSection = () => {
    return (
        <div className="pb-5 pt-10 flex flex-col items-center xxxxxs:px-4
         xxxxs:px-4 xxxs:px-4  xxs:px-4 xs:px-4 lg:px-24  w-full">
            <div className="flex items-center justify-center mt-10 mb-2">
                <CustomButton name="Services" />
            </div>

            <CustomBigTitle title="WHAT WE OFFER HERE" />

            {/* Services Section */}
            <div className="lg:flex flex-wrap justify-center lg:justify-start  w-full">
                <div className="lg:w-1/3 xxxxxs:w-full xxxxs:w-full xxxs:w-full xxs:w-full xs:w-full">
                    <CustomCardForServices
                        imageUrl={imagecourseone}
                        title="Courses"
                        subTitle="Courses are a structured and systematic way to gain knowledge, develop skills, and enhance expertise in various domains. Whether aimed at professional growth, personal development, or academic achievement gain knowledge"
                    />
                </div>
                <div className="lg:w-1/3 xxxxxs:w-full xxxxs:w-full xxxs:w-full xxs:w-full xs:w-full">
                    <CustomCardForServices
                        imageUrl={imageResourses}
                        title="Resources"
                        subTitle="When it comes to enhancing skills, gaining knowledge, or advancing in your career, the right resources can make all the difference. There are a variety of resources available that cater to different learning needs,knowledge"
                    />
                </div>
                <div className="lg:w-1/3 xxxxxs:w-full xxxxs:w-full xxxs:w-full xxs:w-full xs:w-full">
                    <CustomCardForServices
                        imageUrl={imageResourses}
                        title="Events"
                        subTitle="Attending events is an excellent way to expand your knowledge, build relationships, and stay up-to-date with the latest trends in your industry. Whether you are looking for conferences, workshops, webinars, knowledge"
                    />
                </div>
            </div>

            {/* Additional Services Section */}
            <div className="lg:flex flex-wrap justify-center  gap-4 w-full mt-4">
                <div className="lg:w-1/3 xxxxxs:w-full xxxxs:w-full xxxs:w-full xxs:w-full xs:w-full">
                    <CustomCardForServices
                        imageUrl={imagecourseone}
                        title="Blogs"
                        subTitle="Blogs are an excellent medium for sharing valuable content, offering insights, and connecting with an audience. Whether you are writing for a business, educational platform, or personal blog, your content should engage,"
                    />
                </div>
                <div className="lg:w-1/3 xxxxxs:w-full xxxxs:w-full xxxs:w-full xxs:w-full xs:w-full">
                    <CustomCardForServices
                        imageUrl={imagecourseone}
                        title="Consultations"
                        subTitle="Consultations provide valuable one-on-one or group interactions with experts who can offer personalized advice, insights, and strategies to address specific needs or challenges. Whether you are looking for business, career"
                    />
                </div>
            </div>
        </div>
    );
};

export default CustomServicesSection;
