import React, { useState } from 'react';
import { Button } from '@headlessui/react';
import ScrollToggle from '../../users/homeuser/ScrollToggle';
const sections = [
  { id: 'DayOne', label: 'Day 1' },
  { id: 'DayTwo', label: 'Day 2' },
];

const Participants = () => {
  const [activeSection, setActiveSection] = useState(null);

  return (
    <div className=' xxxs:w-full xxs:w-full xs:w-full xxxxs:w-full xxxxxs:w-full 
    sm:w-[600px] lg:w-[600px] xl:w-[700px] 1xl:w-[800px] 11xl:w-[760px] 2xl:w-[900px] 3xl:w-[1100px] 
     bg-white rounded-custom mt-5 p-4'>
         <h3 className='xxxs:text-xl xxs:text-xl xs:text-xl sm:text-3xl lg:text-3xl xl:text-3xl 1xl:text-3xl 11xl:text-3xl 2xl:text-3xl 3xl:text-3xl font-bold pt-2 text-big-text-color '>
         Participants
      </h3>
      <div className='flex'>
        <div className='py-4 px-4 bg-white xxxs:w-[350px] xxs:w-[350px] xs:w-[350px]
         sm:w-[900px] lg:w-[900px] xl:w-[900px] 1xl:w-[900px] 11xl:w-[900px] 2xl:w-[900px] 3xl:w-[900px] flex'>
          {sections.map(section => (
            <Button
              key={section.id}
              onClick={() => setActiveSection(section.id)}
              className={`bg-gray-200 text-black rounded px-8 py-2 m-2 rounded-custom
                ${activeSection === section.id ? 'bg-primary-color text-white' : 'hover:bg-gray-300'}`}
            >
              {section.label}
            </Button>
          ))}
        </div>
      </div>
      <ScrollToggle title='Opening Keynote - Shaping the Future of Education' subtitle='Explore the transformative potential of innovative educational approaches and technologies in shaping the future of learn.
and technologies in shaping the future of learn. Explore the transformative potential of innovative educational approaches and technologies in shaping the future of learning. Explore the transformative potential of innovative educational approaches and technologies in shaping the future of learning the transformative potential of innovative.'/>
      
      <ScrollToggle title='Panel Discussion - Empowering Educators for Student Success' subtitle='Explore the transformative potential of innovative educational approaches and technologies in shaping the future of learn.
and technologies in shaping the future of learn. Explore the transformative potential of innovative educational approaches and technologies in shaping the future of learning. Explore the transformative potential of innovative educational approaches and technologies in shaping the future of learning the transformative potential of innovative.'/> 
    </div>
  );
};

export default Participants;
