import React from 'react';
import { ClockIcon } from '@heroicons/react/solid'; // Assuming you're using Heroicons

const CustomIconAndTextDates = ({ icon, title }) => {

  // Function to handle the click event
  const handleClick = () => {
    // Open Google Calendar to create an event on the specified date (November 16, 2024)
    const googleCalendarURL = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=Sample%20Event&dates=20241116T120000Z/20241116T130000Z&details=This%20is%20a%20sample%20event%20for%20the%20given%20date.&location=Online`;
    window.open(googleCalendarURL, '_blank');
  };

  return (
    <div
      className="flex items-center cursor-pointer xxxs:space-x-1 xxs:space-x-1 xs:space-x-1
       sm:space-x-2 lg:space-x-2
        xl:space-x-2 1xl:space-x-2 11xl:space-x-2 2xl:space-x-2 22xl:space-x-2 3xl:space-x-2
        xxxs:mr-0 xxs:mr-0 xs:mr-0 sm:mr-6 lg:mr-4 xl:mr-4 1xl:mr-4 
        11xl:mr-4 2xl:mr-8 22xl:mr-10 3xl:mr-10"
      onClick={handleClick} // Add onClick event handler
    >
      <div className="xxxs:text-xs xxs:text-xs xs:text-xs lg:text-sm xl:text-sm 2xl:text-lg">
        {icon}
      </div>
      <h3 className="xxxs:text-xs xxs:text-ss xs:text-xs sm:text-xs
       lg:text-xs xl:text-xs 1xl:text-xs 11xl:text-xs 2xl:text-xs
        22xl:text-xs 3xl:text-xs text-gray-500">
        {title}
      </h3>
    </div>
  );
}

export default CustomIconAndTextDates;
