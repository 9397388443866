import React, { useState } from 'react';

const AboutShop = () => {
  const [showMore, setShowMore] = useState(false);

  return (
    <div className='xxxs:w-full xxs:w-full xs:w-full xxxxs:w-full xxxxxs:w-full
    sm:w-[600px] lg:w-[600px] xl:w-[700px] 1xl:w-[800px] 11xl:w-[760px] 2xl:w-[900px] 3xl:w-[1100px] 
bg-white rounded-custom mt-5 p-4'>
      <h3 className='xxxs:text-xl xxs:text-xl xs:text-xl sm:text-3xl lg:text-3xl xl:text-3xl 1xl:text-3xl 11xl:text-3xl 2xl:text-3xl 3xl:text-3xl font-bold text-big-text-color'>
        Info
      </h3>

      <p className='xxxs:text-xs xxs:text-xs xs:text-xs sm:text-lg lg:text-lg xl:text-lg 1xl:text-lg 11xl:text-lg 2xl:text-lg 3xl:text-lg text-big-text-color '>
        Embark on a comprehensive React journey with our React Front To Back course. Designed for beginners and seasoned developers alike, this course covers the fundamentals and dives deep into advanced topics. From building interactive user interfaces to managing state and integrating with APIs, you’ll gain hands-on experience. Elevate your React skills and stay at the forefront of web development trends with this immersive and practical learning experience.
      </p>

      {showMore && (
        <div>
          <div className="flex items-start mt-4">
            <h3 className="xxxs:text-xl xxs:text-xl xs:text-xl sm:text-3xl lg:text-3xl xl:text-3xl 1xl:text-3xl 11xl:text-3xl 2xl:text-3xl 3xl:text-3xl font-bold mr-2">.</h3>
            <h4 className="xxxs:xs xxs:text-xs xs:text-xs sm:text-sm lg:text-sm xl:text-sm 1xl:text-sm 11xl:text-sm 2xl:text-sm 3xl:text-sm text-gray-700">
              Develop a strong understanding of JavaScript basics, including variables, data types, and control flow, building a solid foundation for advanced concepts.
            </h4>
          </div>

          <div className='flex mt-4'>
            <h3 className='xxxs:text-xl xxs:text-xl xs:text-xl sm:text-3xl lg:text-3xl xl:text-3xl 1xl:text-3xl 11xl:text-3xl 2xl:text-3xl 3xl:text-3xl font-bold mr-2'>.</h3>
            <h4 className='xxxs:xs xxs:text-xs xs:text-xs sm:text-sm lg:text-sm xl:text-sm 1xl:text-sm 11xl:text-sm 2xl:text-sm 3xl:text-sm text-gray-700'>
              Dive into modern JavaScript with ES6+ features like arrow functions, destructuring, and modules, enhancing your code readability and efficiency.
            </h4>
          </div>

          <div className='flex mt-4'>
            <h3 className='xxxs:text-xl xxs:text-xl xs:text-xl sm:text-3xl lg:text-3xl xl:text-3xl 1xl:text-3xl 11xl:text-3xl 2xl:text-3xl 3xl:text-3xl font-bold mr-2'>.</h3>
            <h4 className='xxxs:xs xxs:text-xs xs:text-xs sm:text-sm lg:text-sm xl:text-sm 1xl:text-sm 11xl:text-sm 2xl:text-sm 3xl:text-sm text-gray-700'>
              Master asynchronous programming using Promises, async/await, and event loop mechanisms, enabling you to create responsive and efficient applications.
            </h4>
          </div>

          <div className='flex mt-4'>
            <h3 className='xxxs:text-xl xxs:text-xl xs:text-xl sm:text-3xl lg:text-3xl xl:text-3xl 1xl:text-3xl 11xl:text-3xl 2xl:text-3xl 3xl:text-3xl font-bold mr-2'>.</h3>
            <h4 className='xxxs:xs xxs:text-xs xs:text-xs sm:text-sm lg:text-sm xl:text-sm 1xl:text-sm 11xl:text-sm 2xl:text-sm 3xl:text-sm text-gray-700'>
              Explore OOP principles in JavaScript, understanding encapsulation, inheritance, and polymorphism for creating scalable and maintainable code.
            </h4>
          </div>

          <div className='flex mt-4'>
            <h3 className='xxxs:text-xl xxs:text-xl xs:text-xl sm:text-3xl lg:text-3xl xl:text-3xl 1xl:text-3xl 11xl:text-3xl 2xl:text-3xl 3xl:text-3xl font-bold mr-2'>.</h3>
            <h4 className='xxxs:xxs xxs:text-xxs xs:text-xxs sm:text-sm lg:text-sm xl:text-sm 1xl:text-sm 11xl:text-sm 2xl:text-sm 3xl:text-sm text-gray-700'>
              Embrace functional programming concepts, leveraging higher-order functions, closures, and immutability to write clean and modular code.
            </h4>
          </div>

          <div className='flex mt-4'>
            <h3 className='xxxs:text-xl xxs:text-xl xs:text-xl sm:text-3xl lg:text-3xl xl:text-3xl 1xl:text-3xl 11xl:text-3xl 2xl:text-3xl 3xl:text-3xl font-bold mr-2'>.</h3>
            <h4 className='xxxs:xxs xxs:text-xxs xs:text-xxs sm:text-sm lg:text-sm xl:text-sm 1xl:text-sm 11xl:text-sm 2xl:text-sm 3xl:text-sm text-gray-700'>
              Familiarize yourself with modern development tools like Webpack and Babel, streamlining your workflow and optimizing your JavaScript applications.
            </h4>
          </div>

          <p className='xxxs:text-xs xxs:text-xs xs:text-xs sm:text-lg lg:text-lg xl:text-lg 1xl:text-lg 11xl:text-lg 2xl:text-lg 3xl:text-lg text-big-text-color mt-4'>
            Embark on a comprehensive React journey with our React Front To Back course. Designed for beginners and seasoned developers alike, this course covers the fundamentals and dives deep into advanced topics. From building interactive user interfaces to managing state and integrating with APIs, you’ll gain hands-on experience. Elevate your React skills and stay at the forefront of web development trends with this immersive and practical learning experience.
          </p>
        </div>
      )}

      <button
        onClick={() => setShowMore(!showMore)}
        className='mt-4 text-primary-color hover:underline'
      >
        {showMore ? 'See Less' : 'See More'}
      </button>
    </div>
  );
};

export default AboutShop;