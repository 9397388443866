import React, { useState, useEffect } from 'react';
import HeaderHomeUserMobile from '../../components/users/sharedcomponent/customMobileHead';
import CustomDivAccount from '../../components/users/accountsettinguser/customDivAccount';
import CustomForm from '../../components/users/accountsettinguser/customForm';
import HeadUser from '../../components/users/sharedcomponent/headuser';
import HeaderHomeUse from '../../components/users/sharedcomponent/headerhomeuser';
import FlyoutMenu from '../../components/users/sharedcomponent/flayoutmenus';
import CustomDivider from '../../components/users/sharedcomponent/customdivider';
import FooterUser from '../../components/users/sharedcomponent/footeruser';
import CustomNavBarWithFlayout from '../../components/users/sharedcomponent/customnavbarwithflayout';
import CustomStaticFlyoutMenu from '../../components/users/sharedcomponent/customFlayoutinStaticHeader';
const AccountUser = () => {
    const [view, setView] = useState('grid');
    const [isNavBarVisible, setIsNavBarVisible] = useState(false);
 
    useEffect(() => {
      const handleScroll = () => {
        setIsNavBarVisible(window.scrollY > 100);
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);
  
    return (
        <div className="relative">
        <div className={`transition-all duration-300 ${isNavBarVisible ? 'pt-16' : 'pt-0'}`}>
           <HeadUser />
        <HeaderHomeUse />
        <CustomDivider />
        <div className='flex justify-center items-center hidden-mobile'>
          <CustomStaticFlyoutMenu  />
        </div>
      </div>
      <CustomDivider />
      <div className={`fixed mobile-only top-0 left-0 w-full  bg-white z-50 transition-transform duration-300 ${isNavBarVisible ? 'translate-y-0' : '-translate-y-full'}`}>
    <HeaderHomeUserMobile />
</div>

        <div className={`fixed hidden-mobile top-0 left-0 w-full bg-white z-50 transition-transform duration-300 ${isNavBarVisible ? 'translate-y-0' : '-translate-y-full'}`}>
          <CustomNavBarWithFlayout />
        </div>
            <div className='bg-gray-50 z-0'>
                <CustomDivAccount />
                <div className='relative sm:top-[-220px] lg:top-[-140px]
              px-1 mt-10 mb-10 justify-center'>
                <CustomForm/>
                </div>
                <FooterUser />
            </div>
        </div>
    );
};

export default AccountUser;
