import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/solid";
import ShadowButton from '../sharedcomponent/buttonshadow';
import imageOne from '../../../asset/images/imagefx.jpg';
import imageTwo from '../../../asset/images/image_fx.jpg';
import imgRightIcon from '../../../asset/images/arrowrightwhite.png';
const DragCards = () => {
  // Array of text content with associated background image URLs
  const textContent = [
    { text: "Welcome to the Future of Education", bgImage: imageOne },
    { text: "Empowering Professionals Worldwide", bgImage: imageTwo },
  ];

  const [activeTextIndex, setActiveTextIndex] = useState(0);

  // Change text every 10 seconds (10000ms) for slower transition
  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveTextIndex((prevIndex) => (prevIndex + 1) % textContent.length);
    }, 10000); // Change text every 10 seconds (slower)

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  // Handle the previous and next arrows click
  const handlePrevSlide = () => {
    setActiveTextIndex((prevIndex) =>
      prevIndex === 0 ? textContent.length - 1 : prevIndex - 1
    );
  };

  const handleNextSlide = () => {
    setActiveTextIndex((prevIndex) => (prevIndex + 1) % textContent.length);
  };

  return (
    <motion.section
      key={activeTextIndex} // Ensure full re-render when text or background changes
      className="xs:px-2 xxs:px-2 xxxs:px-2 lg:px-24 xl:px-24 1xl:px-24 11xl:px-24 2xl:px-24 3xl:px-24
       relative xs:h-full xxs:h-full xxxs:h-full xxxxs:h-full xxxxxs:h-full
       sm:h-[400px] lg:h-[500px] xl:h-[500px]  2xl:h-[500px]  w-full overflow-visible pt-8"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {/* Left Arrow */}
      <div
        className="absolute top-1/2 xs:left-2 xxs:left-2 xxxs:left-2 xxxxs:left-2 xxxxxs:left-2 sm:left-12 transform -translate-y-1/2 cursor-pointer z-10 bg-primary-color p-2 rounded-full shadow-lg"
        onClick={handlePrevSlide}
      >
        <ArrowLeftIcon className="w-6 h-6 text-white  hover:text-gray-800" />
      </div>

      {/* Right Arrow */}
      <div
        className="absolute top-1/2 xs:right-2 xxs:right-2 xxxs:right-2 xxxxs:right-2 xxxxxs:right-2 sm:right-12 transform -translate-y-1/2 cursor-pointer z-10 bg-primary-color p-2 rounded-full shadow-lg"
        onClick={handleNextSlide}
      >
        <ArrowRightIcon className="w-6 h-6 text-white hover:text-gray-800" />
      </div>

      {/* Wrapper for background image */}
      <motion.div
        className="absolute inset-0 bg-cover bg-center transition-all duration-1000"
        style={{
          backgroundImage: `url(${textContent[activeTextIndex]?.bgImage})`,
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      />

      <div className="flex-1 flex flex-col justify-center px-4 pt-0 pb-4 relative z-10">
        {/* Main Heading */}
        <div className="mb-3 font-k2d mt-20">
          <div className="flex flex-col sm:flex-row">
            <h1 className="text-2xl sm:text-4xl
  xs:ml-8 xxs:ml-8 xxxs:ml-8 xxxxs:ml-8 xxxxxs:ml-8
             lg:text-4xl xl:text-4xl 2xl:text-5xl 3xl:text-6xl font-normal 
             tracking-tight text-big-text-color pr-1">
              {textContent[activeTextIndex]?.text}
            </h1>
          </div>
        </div>

        {/* Paragraph text */}
        <p className="mt-4 text-sm  sm:text-lg 
         xs:ml-8 xxs:ml-8 xxxs:ml-8 xxxxs:ml-8 xxxxxs:ml-8

        xs:w-2/3  xxs:w-2/3  xxxs:w-2/3  xxxxs:w-2/3  xxxxxs:w-2/3
        lg:text-lg xl:text-lg 2xl:text-2xl 3xl:text-3xl text-big-text-color 
        sm:w-[600px] lg:w-[600px] xl:w-[600px] 1xl:w-[650px] 11xl:w-[650px] 2xl:w-[900px] 3xl:w-[1100px] font-k2d">
          Business Index Sentiments Education is your source for understanding talent development Dr. Tarek Tantawi.
        </p>

        {/* Call to Action */}
        <div className="w-200 mt-6 
      xs:ml-8 xxs:ml-8 xxxs:ml-8 xxxxs:ml-8 xxxxxs:ml-8

        sm:mb-15 lg:mb-5 xl:mb-5 11xl:mb-5 1xl:mb-5 2xl:mb-5 3xl:mb-5
         xxxs:mb-20 xxs:mb-20 xs:mb-20 xxxxs:mb-20 xxxxxs:mb-20">
          <ShadowButton image={imgRightIcon}>Create Account</ShadowButton>
        </div>
      </div>
    </motion.section>
  );
};

export default DragCards;
